// Dutch (Language-Country Code: nl-NL) Locale File.
import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const nl_NL: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            backToActivityTimes: 'Terug naar activiteittijden',
            backToActivityDetails: 'Terug naar activiteitinformatie',
            backToEvent: 'Terug naar Event',
            location: 'Locatie',
            rsvp: '',
            entry: '',
            soldOut: 'VOLGEBOEKT',
            cancel: 'Annuleren',
            close: 'Sluiten',
            confirm: 'Bevestigen',
            exit: '',
            signInCreateAccount: 'Inloggen / Account aanmaken',
            signIn: 'Inloggen',
            warpPipePass: '',
            learnMore: 'Meer info',
            currentLanguage: 'Nederlands',
            yes: '',
            no: '',
            note: 'OPMERKING',
            addressFormat: '{address} {postal_code}, {city}, {region}',
            addressFormatHideRegion: '{address} {postal_code}, {city}',
            areYouSure: 'Weet je het zeker?',
        },
        tabs: {
            events: 'Events',
            myRSVPs: '',
            myTicketsAndPasses: 'Mijn tickets / Passes',
            checkIn: '',
            signIn: '',
        },
        events: {
            eventItemHeader: 'Event:',
            upcoming: '',
            moreInfo: 'Meer info',
            eventDetails: 'Eventinformatie',
            viewEventDetails: 'Eventinformatie bekijken',
            details: {
                tabs: {
                    tickets: 'Tickets',
                    activities: 'Activiteiten',
                    resources: '',
                },
                accessCode: {
                    aria: {
                        accessCodeInput: 'Toegangscode',
                        exitButton: 'Toegangscodepaneel verlaten',
                    },
                    title: 'Toegangscode invoeren',
                    toggleButtonLabel: 'Toegangscode inwisselen',
                    redeemButtonLabel: 'Inwisselen',
                    inputPlaceHolder: '16-cijferige toegangscode',
                    enterDifferentCode: 'Andere toegangscode invoeren',
                    success: {
                        woohoo: 'Woo-hoo!',
                        youCanRegister: 'Je kunt je nu registreren voor:',
                    },
                    error: {
                        invalid: 'Ongeldige code. Probeer het opnieuw.',
                        registrationClosed: 'Registratie voor dit ticket is gesloten.',
                    },
                },
                waitlist: {
                    available: 'Wachtlijst beschikbaar',
                    drawingOver:
                        'De loting is voorbij, maar je kunt nog steeds kans maken om het evenement te bezoeken. Als je je aansluit bij de wachtlijst, laten we het je weten als er een plek voor je vrijkomt. Houd er rekening mee dat aanmelding voor de wachtlijst niet garandeert dat je tickets ontvangt.',
                    drawingLost:
                        'Je bent niet geloot voor tickets voor dit evenement. Als je wilt, kun je je aansluiten bij de wachtlijst, zodat we je een bericht kunnen sturen als er een plek voor je vrijkomt. Houd er rekening mee dat aanmelding voor de wachtlijst niet garandeert dat je tickets ontvangt.',
                    waitlistJoined: 'Je staat op de wachtlijst voor {eventName}',
                    waitlistLink: 'Wachtlijst bekijken >',
                    waitlistOffer:
                        'Hoera! Er is een aanbod beschikbaar voor een van de wachtlijsten waar je je voor hebt aangemeld. Je hebt tot {monthDay} om {time} de tijd om dit aanbod te accepteren. Na die datum zal het aanbod overgaan naar de volgende persoon op de wachtlijst.',
                    offerPassed:
                        'Je hebt het aanbod voor {ticketName} afgewezen. Als je wilt, kun je je opnieuw bij de wachtlijst aansluiten.',
                    offerExpired:
                        'Je aanbod voor {ticketName} is verlopen. Als je wilt, kun je je opnieuw bij de wachtlijst aansluiten.',
                    waitlistClosed: 'De wachtlijst is gesloten.',
                    button: {
                        join: 'Aansluiten bij wachtlijst',
                        edit: 'Wachtlijstkeuzes wijzigen',
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Wettige naam',
                FIRST_NAME: 'Voornaam',
                LAST_NAME: 'Achternaam',
                POSTAL_CODE: 'Postcode',
                PHONE: 'Telefoonnummer',
                US_ADDRESS: 'Postadres',
                MAILING_ADDRESS: {
                    streetAddress: 'Straatnaam',
                    aptOrSuite: 'Appartement/Suite',
                    city: 'Stad',
                    state: 'Provincie',
                    country: 'Land',
                    zipCode: 'Postcode',
                },
                OPT_IN: 'Opt-in voor delen per e-mail',
                ACCESS_CODE: 'Toegangscode',
            },
            ageRatingUrl: 'https://pegi.info/',
        },
        activities: {
            activities: 'Activiteiten',
            viewActivities: 'Activiteiten bekijken',
            registrationNotBegun: 'Je kunt je voor deze activiteit nog niet registreren',
            registrationHasEnded: 'Registratie voor deze activiteit is gesloten',
            registrationBegins: 'Registratie begint',
            details: {
                title: 'Activiteitinformatie',
                registrationBegins: 'Registratie begint:',
                registrationEnds: 'Registratie eindigt:',
                header: {
                    noAvailableTimes: 'Helaas, er zijn geen tijden beschikbaar.',
                },
                continueToRSVP: 'Verdergaan',
                viewMy: 'Mijn {0} bekijken',
                alreadyRSVPd: 'Je hebt al een Warp Pipe Pass voor deze activiteit.',
                ticketIsRequired:
                    'Je hebt een ticket voor dit event nodig voordat je je voor deze activiteit kunt aanmelden. {link}.',
                eventTicketRequired: 'Eventticket vereist',
                ticketIsPendingDrawing:
                    'Je ticket voor het evenement is nog steeds in afwachting van een trekking. Kom nog eens terug nadat de trekking heeft plaatsgevonden.',
                checkForTicketAvailability: 'Kijk hier voor beschikbare tickets',
                label: {
                    startDate: 'Startdatum is {startDate}',
                    activityType: 'Type activiteit is {activityType}',
                },
                iconLabel: {
                    activityTime: 'Pictogram voor activiteitstijd',
                    activityType: 'Pictogram voor activiteitstype',
                    registration: 'Pictogram voor registratietype',
                    location: 'Pictogram voor locatie',
                },
            },
            timeSlots: {
                title: '',
                noTimesForDay: 'Er zijn voor deze dag geen tijden beschikbaar.',
            },
            unknownActivityType: '',
            types: {
                GAME_DEMO: 'Gamedemo',
                GENERAL_CHECK_IN: 'Algemene check-in',
                COMPETITIVE_PLAY: 'Competitief spelen',
                EXCLUSIVE_OFFER: 'Exclusieve aanbieding',
                LEAD_GENERATION: 'Leads verzamelen',
                MEET_GREET: 'Meet & Greet',
                PRODUCT_LAUNCH: 'Productlancering',
                SPECIAL_EVENT: 'Speciaal event',
                unknown: '',
            },
        },
        reservations: {
            timeRemainingForReservation:
                'Resterende tijd: {timeRemaining}. Als je deze pagina verlaat of als de tijd om is voordat je het registratieproces hebt voltooid, wordt deze transactie geannuleerd.',
            timeRemainingForDrawing:
                'Je hebt nog {timeRemaining} om deze loterij-aanmelding te voltooien.',
            timeRemainingForWaitlist:
                'Hoera! Je hebt een wachtlijstaanbod voor {numTickets} {ticketName}. Als je hier interesse in hebt, moet je het aanbod accepteren voordat het verloopt: {timeRemaining}',
            waitlistThingsToKeepInMind: 'Houd rekening met het volgende:',
            waitlistThingsToKeepInMindItem1:
                'Als je deze tickets accepteert, word je van de wachtlijst verwijderd voor alle andere tickets van {eventName}.',
            waitlistThingsToKeepInMindItem2:
                'Als je deze registratiepagina afsluit, betekent dat niet dat je je aanbod verliest. Tot de aangegeven tijd heb je de gelegenheid om je ticket(s) te accepteren.',
            waitlistThingsToKeepInMindItem3:
                'Als je het evenement niet langer wilt bezoeken, kun je de aangeboden tickets afwijzen. Hierdoor zullen de tickets worden aangeboden aan de volgende persoon op de wachtlijst.',
            waitlistThingsToKeepInMindItem4:
                'Het afwijzen van aangeboden tickets heeft geen effect op andere aanmeldingen voor de wachtlijst voor {eventName}. Je kunt je later ook opnieuw bij de wachtlijst aansluiten.',
            minutes: ' minuten',
            registrant: '',
            registrantInformation: 'Informatie over je registratie',
            nickname: 'Gebruikersnaam Nintendo-account',
            confirmRSVP: 'Bevestigen',
            confirmEntry: 'Meedoen aan loterij',
            legalConsent: 'Juridische toestemming',
            adultAcknowledgement:
                'Door dit vakje aan te vinken verklaar ik dat ik, en de gast(en) van 18 jaar of ouder in mijn familiegroep, het Privacybeleid hebben gelezen en de onderstaande voorwaarden accepteren.',
            childAcknowledgement:
                'Door dit vakje aan te vinken, verklaar ik dat ik de ouder/wettelijke voogd ben van de gast(en) jonger dan 18 jaar in mijn familiegroep. Ik erken het privacybeleid en accepteer de bovenstaande voorwaarden namens hen.',
            adultGuestsAcknowledgement:
                'Door dit vakje aan te vinken bevestig ik dat ik toestemming heb van mijn volwassen gasten om hen als gasten toe te voegen en dat ze ermee instemmen om per e-mail een uitnodiging te ontvangen. Iedere volwassen gast moet instemmen met de bijgevoegde voorwaarden van de e-mailuitnodiging, voordat ze deelnemen aan het evenement.',
            event_registration: {
                attendees: 'Deelnemers',
                note: 'OPMERKING:',
                attendeeCannotRegister:
                    'Deelnemers kunnen zich niet inschrijven voor meer dan één activiteit tijdens hetzelfde tijdslot, of meer dan één keer op een dag voor dezelfde activiteit.',
                currentUserIneligible:
                    'Je bent al geregistreerd voor een Warp Pipe Pass voor deze activiteit of een andere activiteit die op dezelfde tijd plaatsvindt.',
                removedGuests:
                    '{guests} is verwijderd als gast omdat diegene zich al heeft geregistreerd voor een Warp Pipe Pass voor deze activiteit of een andere activiteit die op dezelfde tijd plaatsvindt.',
                selectAllAttendees: 'Selecteer alle deelnemers voor deze activiteit.',
            },
        },
        tickets: {
            ticket: 'Ticket',
            tickets: 'Tickets',
            wppRequiredForCheckIn: 'Deze {0} is vereist om in te checken.',
            wppsRequiredForCheckIn: 'Deze {0} zijn vereist om in te checken.',
            ticketRequiredForCheckIn: 'Dit {0} is vereist om het event bij te wonen.',
            ticketsRequiredForCheckIn: 'Deze {0} zijn vereist om het event bij te wonen.',
            getTickets: 'Tickets',
            eventTicket: 'Eventticket',
            eventTickets: 'Eventtickets',
            viewTickets: 'Tickets bekijken',
            viewEntry: 'Loterijlot bekijken',
            registrationClosed: 'Registratie gesloten',
            comingSoon: 'Komt binnenkort',
            cancelOtherTicket:
                'Annuleer je andere ticket voordat je je registreert voor dit ticket.',
            soldOut: 'Volgeboekt',
            adultRequired: 'Volwassene vereist',
            adultRequiredTooltip: 'Alleen een volwassene kan zich voor dit event registreren.',
            adultRequiredActivityTooltip:
                'Alleen een volwassene kan zich voor deze activiteit registreren.',
            backToMyTicketsPasses: 'Terug naar Mijn tickets / Passes',
            registrationAlreadyClosed: 'Registratie gesloten op {0} om {1}',
            registrationNotStarted: 'Registratie gaat open op {0} om {1}',
            notFound: 'Tickets niet gevonden',
            unknownTicketType: 'Onbekend tickettype',
            consent: {
                consentRequiredTooltipTitle: 'Toestemming vereist',
                consentRequiredTooltipBody:
                    'Deze gebruiker moet de uitnodiging accepteren en akkoordgaan met de voorwaarden door in te loggen op de Nintendo-account.',
                consentRequiredToolTipBodyHostUrl: 'de uitnodiging voor het event accepteren',
                consentRequiredToolTipBodyHost:
                    'Je moet {0} en de voorwaarden accepteren om deze activiteit bij te wonen.',
            },
            receivedTickets: 'Je hebt je tickets ontvangen voor {resourceName} {resourceDates}.',
            drawings: {
                wonDrawing:
                    'Je hebt bij de loterij een ticket voor {resourceName} {resourceDates} gewonnen.',
                lostDrawing: 'Helaas, je hebt bij de loterij geen ticket gewonnen.',
                pendingDrawing: 'Je doet mee aan de loting voor {resourceName} {resourceDates}.',
            },
            registration: {
                ticketDetails: 'Ticketinfo',
                registrant: {
                    onlyOneActivity:
                        '{note} Deelnemers kunnen zich niet inschrijven voor meer dan één activiteit tijdens hetzelfde tijdslot, of meer dan één keer op een dag voor dezelfde activiteit.',
                    dataNotice:
                        'Bekijk ons {link} voor meer informatie over hoe we je gegevens gebruiken',
                    requiredFields: 'Alle velden met * zijn verplicht',
                },
                guest: {
                    guest: 'Gast',
                    guests: 'Jouw gasten (maximaal {maxGuests})',
                    birthday: 'Geboortedatum',
                    addManageGuests: 'Gast(en) toevoegen/beheren',
                    manageNintendoAccountFamily: 'Nintendo-accountfamiliegroep beheren',
                    guestsRemovedTicket:
                        '{guests} is verwijderd als gast omdat diegene zich al heeft geregistreerd voor een ticket voor dit event.',
                    guestsRemovedDrawing:
                        '{guests} is verwijderd als gast omdat diegene al meedoet aan de loterij voor een ticket voor dit event.',
                    maxLimit: 'Het maximaal aantal gasten is {max}.',
                    guestFamilyHelp:
                        'Heb je nog geen familiegroep aangemaakt? {guestFamilyHelpLink}',
                    guestFamilyHelpLink: 'Ontdek hoe je er een maakt en beheert.',
                    guestFamilyHelpUrl:
                        'https://www.nintendo.com/nl-nl/Support/Nintendo-Switch/Leden-toevoegen-aan-een-familiegroep-1406409.html',
                    guestFamilyWarning:
                        'Gasten kunnen alleen worden toegevoegd vanuit je {nintendoFamilyLink}. Als je je gasten niet in de lijst ziet, voeg ze dan eerst toe aan je Nintendo-accountfamiliegroep.',
                    guestFamilyLink: 'Nintendo-account-familiegroep',
                    guestFamilyUrl:
                        'https://www.nintendo.com/nl-nl/Support/Nintendo-Switch/Leden-toevoegen-aan-een-familiegroep-1406409.html',
                    guestRemoveButtonLabel: 'Verwijder {user} uit groep',
                    selectFamilyMember:
                        'Maak een keuze uit de beschikbare leden uit de Nintendo-accountfamiliegroep',
                    counter: '{current} / {max} gast(en) geselecteerd',
                    child: 'Kind',
                    notSelectableIf: 'Je kunt familieleden niet selecteren als',
                    reachedMaxGuests: '1. Je het maximale aantal gasten hebt bereikt.',
                    childAndNotParent: '2. Het kinderen zijn en jij niet de ouder/voogd bent.',
                    alreadyRegistered: '3. Ze al zijn geregistreerd.',
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Ticket annuleren',
                cancelLink: 'Dit ticket annuleren',
                cancelTicketModalWarning: {
                    line1: 'Zonder ticket kun je dit evenement niet bijwonen.',
                    line2: 'Je ticket en je Warp Pipe Pass(es) worden geannuleerd.',
                    line3: 'Als je je ticket annuleert, worden automatisch de ticket(s) en Warp Pipe Pass(es) voor iedereen in je groep (indien van toepassing) geannuleerd. Iedereen ontvangt een e-mail ter bevestiging van de annulering.',
                    line4: 'Alle annuleringen zijn definitief. Tickets en Warp Pipe Passes worden niet gehonoreerd als ze worden geannuleerd.',
                },
                areYouSure: 'Weet je zeker dat je dit ticket wilt annuleren?',
                imSure: 'Ja, ik weet het zeker',
                cancelButton: 'Ticket annuleren',
                cancelYourTicket: 'Kun je er niet bij zijn? Annuleer je ticket(s)',
            },
            cancelPass: {
                cancelTicketModalTitle: 'Pass annuleren',
                cancelLink: 'Deze Pass annuleren',
                areYouSure: 'Weet je zeker dat je deze Warp Pipe Pass wilt annuleren?',
                imSure: 'Ja, ik weet het zeker',
                cancelButton: 'Pass annuleren',
            },
            cancelDrawingEntry: {
                cancelTicketModalTitle: 'Loterijdeelname annuleren',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Je wordt verwijderd uit de loting voor dit ticket.',
                    line2: 'Als je je deelname aan de loterij annuleert, wordt automatisch deelname voor iedereen in je groep (indien van toepassing) geannuleerd. Iedereen ontvangt een e-mail ter bevestiging van de annulering.',
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Weet je zeker dat je je loterijlot wilt intrekken?',
                imSure: 'Ja, ik weet het zeker',
                cancelButton: 'Loterijdeelname annuleren',
            },
            waitlist: {
                waitlist: 'Wachtlijst',
                title: 'Aansluiten bij wachtlijst voor {eventName}',
                editTitle: 'Wachtlijstkeuzes voor {eventName} wijzigen',
                subtitle: 'We zullen het je laten weten als er een plek vrijkomt!',
                notice: {
                    line1: '{notice}: tickets worden aangeboden op basis van wie zich als eerste voor de wachtlijst heeft aangemeld, om hoeveel tickets er wordt gevraagd en hoeveel tickets er beschikbaar worden.',
                    line2: 'Je kunt slechts één aanbod tegelijk ontvangen.',
                    line3: 'Je hebt {timeFrame} de tijd om een ticketaanbod te accepteren voordat het verloopt.',
                    line4: 'Als je een aanbod accepteert, word je verwijderd van de wachtlijst voor alle overige tickets.',
                    notice: 'Let op',
                    timeFrame: '36 uur',
                },
                individualTicketsHeader: 'Individuele tickets',
                familyTicketsHeader: 'Familietickets',
                guests: 'Gasten',
                individual: 'Individueel',
                family: 'Familie',
                adultGuests: 'Volwassen gasten (18 jaar en ouder)',
                childGuests: 'Jonge gasten (7 tot 17 jaar)',
                guestCount: '{currentGuests}/{maxGuests} gast(en) gekozen',
                select: 'Kies de wachtlijst(en) waar je je bij wilt aansluiten',
                selectAll: 'Alle tickets van het type "{waitlistType}" kiezen',
                submit: 'Aansluiten bij wachtlijst',
                summary: 'Samenvatting',
                summaryRequired:
                    'Je moet instemmen met de voorwaarden om je te kunnen aansluiten bij de wachtlijst.',
                summaryCheckbox:
                    'Door dit vakje aan te vinken bevestig ik dat ik me ervan bewust ben dat een aanmelding voor de wachtlijst niet garandeert dat ik tickets zal ontvangen. Ik ben me er ook van bewust dat als ik een aanbod ontvang, ik 36 uur de tijd heb om het te accepteren voordat het vervalt.',
                summaryFooter:
                    'Je staat op het punt om een verzoek in te dienen om je aan te sluiten bij de wachtlijst voor {eventName}. Als je een aanbod voor tickets ontvangt, word je hierover geïnformeerd via een e-mail. Om er zeker van te zijn dat je e-mails van ons blijft ontvangen, raden we je aan om ons e-mailadres ({emailAddress}) aan je adresboek toe te voegen. Let er ook op dat er geen e-mails in de map Spam terechtkomen. Als je een aanbod ontvangt, heb je 36 uur de tijd om het te accepteren voordat het vervalt.',
                summaryEmail: 'no-reply@noa.nintendo.com',
                onTheWaitlist: 'Je staat op de wachtlijst:',
                leaveWaitlists: 'Wachtlijsten verlaten',
                leaveWaitlist: 'Wachtlijst verlaten',
                makeChanges: 'Wijzigingen maken',
                cancelEntry: 'Inschrijving annuleren',
                onWaitlistRequestingTickets:
                    'Op wachtlijst (verzoek om {count} evenementticket(s))',
                confirmRemoveSelfFromWaitlistsForEvent:
                    'Je staat op het punt om jezelf te verwijderen van de wachtlijst voor {eventName}. Als je besluit om dit te doen, zul je daarna niet langer worden geïnformeerd als er tickets beschikbaar zijn.',
                confirmRemoveSelfFromWaitlist:
                    'Door je inschrijving te annuleren verwijder je jezelf van de wachtlijst voor {ticketName} en zul je niet langer worden geïnformeerd als er tickets beschikbaar zijn.',
                stayOnWaitlists: 'Op wachtlijsten blijven',
                stayOnWaitlist: 'Op wachtlijst blijven',
                noteOtherWaitlistsUnaffected:
                    'Houd er rekening mee dat dit geen invloed heeft op andere wachtlijsten waar je jezelf voor hebt aangemeld.',
                congratulationsTicketWaitlistOffered:
                    'Gefeliciteerd, je hebt een ticketaanbod voor {eventName} ontvangen! Accepteer dit aanbod vóór {claimByDate} om je deelname zeker te stellen.',
                congratulationsEventTicketWaitlistOffered:
                    'Gefeliciteerd, je hebt een ticketaanbod ontvangen! Open dit bericht voor meer informatie.',
                claimTickets: 'Ticketaanbod accepteren',
                passOnTickets: 'Ticketaanbod afwijzen',
                areYouSureYouDontWantIt: 'Weet je zeker dat het wilt afwijzen?',
                passOnTicketsNotice:
                    'Als je dit ticketaanbod afwijst, kun je de aangeboden ticket niet langer ontvangen en word je verwijderd van de wachtlijst.',
                keepOffer: 'Ticketaanbod accepteren',
                passOnOffer: 'Ticketaanbod afwijzen',
                removeWaitlistTicketsWarning:
                    'Als je je inschrijving annuleert, verwijder je jezelf van de wachtlijst voor beschikbare tickets en zul je niet langer worden geïnformeerd als er tickets beschikbaar zijn.',
                updateWaitlist: 'Wachtlijst bijwerken',
                theWaitlistIsClosed: 'De wachtlijst is gesloten.',
                youClaimed: 'Je hebt {ticketsCount} voor {eventName} aangenomen.',
                yourOfferHasExpired:
                    'Je aanbod voor {ticketName} is verlopen. Als je wilt, kun je je opnieuw aansluiten bij de wachtlijst.',
                youPassedOnThisOffer:
                    'Je hebt het aanbod voor {ticketName} afgewezen.  Als je wilt, kun je je opnieuw aansluiten bij de wachtlijst.',
                offerExpired: 'Aanbod verlopen',
                offerPassed: 'Aanbod afgewezen',
                noLongerOnTheWaitlist:
                    'Je staat niet langer op de wachtlijst voor {eventName}. Als je wilt, kun je je opnieuw bij de wachtlijst aansluiten.',
            },
            filter: {
                allDays: 'Alle dagen',
                filterTicketsLabel: 'Tickets filteren:',
                ticketTypes: {
                    ALL: 'Alle tickets',
                    INDIVIDUAL: 'Individuele tickets',
                    FAMILY: 'Familietickets',
                },
            },
            appleWalletAddButtonAlt: 'Voeg toe aan Apple Wallet',
        },
        terms: {
            acceptTerms: 'Voorwaarden accepteren',
            acceptEventTerms: 'Eventvoorwaarden accepteren',
            addedToDrawing:
                'Je bent door {invitor} toegevoegd aan de loting om een ticket te ontvangen voor het volgende Nintendo-event. Om in aanmerking te komen voor de loting moet je de voorwaarden van het event accepteren.',
            addedToEvent:
                'Je hebt een ticket voor het volgende Nintendo-event ontvangen van {invitor}. Om het event te kunnen bezoeken moet je de voorwaarden van het event accepteren.',
            completeInformation: 'Geef de volgende informatie op:',
            terms: '',
            viewAndAcceptTerms: 'Voorwaarden bekijken en accepteren',
            pendingTermsConsent: 'In afwachting van toestemming voorwaarden',
        },
        error: {
            error: 'Fout',
            warning: 'Opmerking',
            modal: {
                close: 'Sluiten',
            },
            notFound: {
                title: 'Helaas, de pagina of het event waarnaar je zoekt is niet gevonden.',
                viewAvailableEvents: 'Beschikbare events bekijken',
            },
            eventsError:
                'Er is een fout opgetreden tijdens het ophalen van de lijst met events. Probeer het later nog eens.',
            eventDetailsError:
                'Er is een fout opgetreden tijdens het ophalen van info over events. Probeer het later nog eens.',
            activityDetailsError:
                'Er is een fout opgetreden tijdens het ophalen van info over activiteiten. Probeer het later nog eens.',
            timeSlotError:
                'Er is een fout opgetreden tijdens het ophalen van info over tijdslots. Probeer het later nog eens.',
            tempReservationError:
                'Er is een fout opgetreden bij het vastzetten van deze reservering. Probeer het later nog eens.',
            myRSVPsError:
                'Er is een fout opgetreden bij het ophalen van je bevestiging. Probeer het later nog eens.',
            mapError: 'De kaart van de locatie kan momenteel niet worden weergegeven.',
            dataCollectionError:
                'Er is een fout opgetreden tijdens het ophalen van gegevens. Probeer het later nog eens.',
            reservationCouldNotBeConfirmed:
                'De reservering kon niet worden bevestigd. Probeer het later nog eens.',
            reservationCancelFailure:
                'Er is een fout opgetreden tijdens het annuleren. Probeer het later nog eens.',
            reservationFormError: 'Alle verplichte velden moeten worden ingevuld.',
            eventServiceUnavailable:
                'Het systeem is momenteel niet beschikbaar. Probeer het later nog eens.',
            rsvpTimeOut: 'Helaas, je registratie is verlopen. Registreer je opnieuw.',
            waitlistRsvpTimeOut: 'Het spijt ons, maar je wachtlijstticketaanbod is verlopen.',
            ticketConfirmationFailed:
                'De bevestiging van het eventticket is mislukt. Controleer de ingevoerde gegevens en probeer het opnieuw.',
            waitlistEntriesCreationFailed:
                'Je aanmelding voor de wachtlijst is mislukt. Controleer de gegevens die je hebt ingevuld en probeer het opnieuw.',
            serviceError: {
                GP0009: 'Geen beschikbare plaatsen in het gevraagde tijdslot.',
                GP0010: 'Je bent al geregistreerd. Je kunt je niet voor meer dan één activiteit in hetzelfde tijdslot inschrijven, of meer dan één keer op een dag voor dezelfde activiteit.',
                GP0011: '',
                GP0013: 'De registratie voor de activiteit is nog niet gestart.',
                GP0014: 'De registratie voor de activiteit is beëindigd.',
                GP0015: 'Kan geen reservering vinden om te annuleren.',
                GP0016: 'Tijdslot is al geweest.',
                GP0243: 'Je bent al toegevoegd voor een ticket voor dit evenement.',
                GP0244: 'Je doet al mee aan de loting voor dit evenement.',
                GP0252: 'Je hebt al een Warp Pipe Pass voor deze activiteit.',
                unknown: 'Er is een fout opgetreden',
            },
            serviceErrorModal: {
                GP212: {
                    title: 'Registratie niet open',
                    body: 'Registratie voor dit ticket is nog niet open.',
                },
                GP213: {
                    title: 'Registratie gesloten',
                    body: 'Registratie voor dit ticket is gesloten.',
                },
                GP0214: {
                    title: 'Eventticket niet beschikbaar',
                    body: 'Sorry, het evenement dat je hebt geselecteerd is volgeboekt. Controleer of er andere tickets beschikbaar zijn.',
                },
                GP0218: {
                    title: 'Niet beschikbaar',
                    body: 'Je doet al mee aan de loting voor dit ticket.',
                },
                GP220: {
                    title: 'Registratie niet open',
                    body: 'Registratie voor dit ticket is nog niet open.',
                },
                GP221: {
                    title: 'Registratie gesloten',
                    body: 'Registratie voor dit ticket is gesloten.',
                },
                unknown: {
                    title: 'Onbekende fout',
                    body: 'Er is een onbekende fout opgetreden. Probeer het opnieuw.',
                },
            },
            formError: {
                required: '{attribute} is vereist.',
                default: '{attribute} is ongeldig.',
            },
            drawingCancelingError: 'Fout bij het annuleren van deelname aan loting',
            ticketCancelingError: 'Fout bij het annuleren van ticket',
            timeSlotCancelingError: 'Fout bij het annuleren van Pass',
            minNumberOfGuestsError:
                'Voeg {atLeastNum} lid/leden van je Nintendo-accountfamiliegroep toe om te registreren.',
            atLeast: 'minimaal {expectedNumberOfGuests}',
            waitlist: {
                maxNumberOfGuestsError:
                    'Voeg tot aan het maximale aantal {maxGuestAmount} Nintendo-account-familiegroepleden toe om je te registreren.',
                noTicketsSelectedError: 'Kies minimaal één evenementticket om verder te gaan.',
                claimTempTicketsError:
                    'Er is een fout opgetreden bij het accepteren van een wachtlijstticketaanbod.',
                passWaitlistError:
                    'Er is een fout opgetreden bij het afwijzen van een wachtlijstticketaanbod.',
            },
        },
        drawing: {
            drawing: 'Loting',
            firstComeFirstServed: 'Op = op',
            enterForAChance: 'Maak kans op een ticket',
            youHaveAlreadyEntered: 'Je doet al mee aan de loting voor deze activiteit.',
            drawingRules: 'Regels loting',
            ruleX: 'Regel: {rule}',
            status: {
                pending: 'In afwachting van loting ({partySize} {type})',
                notSelected: '',
                notWinner: 'Niet geselecteerd',
                winner: 'Geselecteerd',
            },
        },
        account: {
            accountRequired: 'Hiervoor heb je een Nintendo-account nodig',
            pleaseLogin: {
                basic: '{0} op je Nintendo-account.',
                toView: '{0} op je Nintendo-account om je {1} te bekijken.',
                toAccept: '{0} op je Nintendo-account om de voorwaarden te accepteren.',
            },
            requiredToRSVP:
                'Je hebt een Nintendo-account nodig om je te registreren. Log in of maak een gratis account aan om verder te gaan.',
            rsvpWillBeHeld: '',
            time: '10 minuten',
            checkIn: {
                title: '',
                presentCode: '',
                poorInternetConnectivity:
                    'Als er tijdens je event een slechte internetverbinding is, raden we je aan deze Pass af te drukken op deze pagina of via de e-mail die we hebben gestuurd naar het e-mailadres dat is gekoppeld aan je Nintendo-account. (Als je deze e-mail niet ziet, controleer dan je spam-map).',
                checkInCode: '',
                appleWalletTM:
                    'Apple Wallet is a trademark of Apple Inc., registered in the U.S. and other countries and regions.',
            },
            passes: {
                warpPipePass: 'Warp Pipe Pass',
                warpPipePasses: 'Warp Pipe Passes',
            },
            rsvps: {
                activeRSVPs: '',
                drawingEntries: '',
                rsvps: 'Tickets en Warp Pipe Passes',
                viewPass: 'Pass bekijken',
                reservationUpdated: '{0} is bijgewerkt.',
                reservationRemoved: '{0} is verwijderd.',
                ticketAdded: 'Je hebt je {ticketName} met succes ontvangen.',
                drawingTicketAdded: 'Je aanmelding voor de trekking voor {ticketName} is gelukt.',
                warpPipePassAdded: 'Je hebt je Pass voor {activityName} met succes ontvangen.',
                waitlistAdded: 'Je bent toegevoegd aan de wachtlijst voor {eventName}.',
                waitlistsUpdated: 'Je wachtlijstkeuzes voor {eventName} zijn bijgewerkt.',
                edit: {
                    title: 'Tijd wijzigen',
                },
                cancel: {
                    title: '',
                    entry: 'Loterijlot intrekken',
                    pass: '',
                    ticket: 'Ticket annuleren',
                    areYouSure: '',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/nl/apple_wallet_badge_nl.svg'),
                    },
                    noneFound: '',
                    requiredForCheckIn: 'Deze {0} is vereist om in te checken.',
                    addToCalendar: 'In kalender zetten',
                },
            },
        },
        noneFound: {
            events: 'Er zijn momenteel geen events waarvoor je je kunt registreren. Kom later nog eens terug. Hartelijk dank.',
            activities: 'Er zijn momenteel geen activiteiten beschikbaar voor dit event.',
            timeSlots: 'Er zijn geen tijdslots gevonden voor deze activiteit.',
            reservations: '',
            warpPipePasses: 'Je hebt momenteel geen actieve Warp Pipe Passes',
            tickets: 'Je hebt momenteel geen actieve eventtickets',
        },
        footer: {
            disclaimer:
                'De Pass en/of het ticket is voor eenmalig gebruik, niet overdraagbaar en kan niet worden verkocht. De Pass en/of het ticket kunnen worden ingewisseld voor voorrang bij het wachten in de rij voor een bepaald spel of evenement op een aangewezen tijdstip en kunnen niet worden ingewisseld als ze worden gebruikt tijdens een niet-aangewezen tijdstip. QR Code is een geregistreerd handelsmerk van DENSO WAVE INCORPORATED. Handelsmerken van Nintendo zijn eigendom van Nintendo. © Nintendo.',
            privacyPolicy: 'Privacybeleid',
            privacyPolicyUrl:
                'https://www.nintendo.com/nl-nl/Wet-en-regelgeving/Privacybeleid-van-de-website/Privacybeleid-van-de-website-637785.html',
            termsOfUse: '',
            termsOfUseUrl: '',
        },
        dates: {
            day: 'DD',
            dayOfWeekShort: 'ddd',
            dayOfWeek: 'dddd ',
            fullDate: 'ddd DD MMMM YYYY - HH:mm',
            dateRangeFirst: 'DD MMM',
            dateRangeSecond: 'DD MMM YYYY',
            hourMinute: 'HH:mm',
            hourMinutesTimezone: 'HH:mm',
            month: 'MMM',
            monthDateYear: 'DD MMM YYYY',
            monthDateYearLong: 'DD MMMM YYYY',
            range: ' - ',
            reservationDate: 'dddd D MMM YYYY',
            reservationDateShort: 'ddd D MMM YYYY',
            reservationTime: 'HH:mm',
            registrationDate: 'ddd DD MMM YYYY - HH:mm',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'HH:mm',
                timeSlotHourMinute: 'HH:mm',
                timeSlotTop: 'ddd',
                timeSlotMiddle: 'DD',
                timeSlotBottom: 'MMM',
            },
            eventTickets: {
                dateRange: 'ddd DD MMM YYYY',
                time: 'HH:mm',
                same_day_time: 'HH:mm',
                timeZone: '',
                cancel: {
                    time: 'dddd D MMM YYYY - HH:mm',
                },
                waitlistClaimBy: 'H:mm [om] dddd D MMMM',
            },
            birthday: 'D MMMM YYYY',
            formatting: {
                ISO8601ToTimezone: '',
            },
        },
        countries: {
            title: 'Land: {0}',
            values: {
                ALL: 'Alle',
                US: 'Verenigde Staten',
                CA: 'Canada',
                // JP: 'Japan',
                AU: 'Australië',
                GB: 'Verenigd Koninkrijk & Ierland',
                DE: 'Duitsland',
                FR: 'Frankrijk',
                ES: 'Spanje',
                IT: 'Italië',
                NL: 'Nederland',
                // PT: 'Portugal',
                // BE: 'België',
                // CH: 'Zwitserland',
                // AT: 'Oostenrijk',
                MX: 'Mexico',
            },
        },
        locales: {
            en_US: 'Engels (US)',
            en_GB: 'Engels (UK)',
            en_AU: 'Engels (Australië)',
            de_DE: 'Duits',
            fr_FR: 'Frans (Frankrijk)',
            fr_CA: 'Frans (Canada)',
            it_IT: 'Italiaans',
            es_ES: 'Spaans (Spanje)',
            es_MX: 'Spaans (Mexico)',
            nl_NL: 'Nederlands',
            // pt_PT: '',
        },
        files: {
            fileCategory: {
                GENERAL_INFO: 'Algemene informatie',
                LEGAL: 'Juridische informatie',
                TOURNAMENT_INFO: 'Toernooi-informatie',
            },
        },
    },
    en_US,
);

export default nl_NL;
