/* GENERAL */
import React from 'react';
import { Params } from 'react-router-dom';
import { AccountInfo } from '../context/AccountContext';

export enum Audience {
    PUBLIC = 'PUBLIC',
    NOW_PASS = 'NOW_PASS',
    CORPORATE_EVENT = 'CORPORATE_EVENT',
    PRIVATE = 'PRIVATE',
}

export enum Status {
    PENDING = 'PENDING',
    RESERVED = 'RESERVED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    TEMPORARY = 'TEMPORARY',
    ENTERED = 'ENTERED',
    NOT_SELECTED = 'NOT_SELECTED',
}

export enum DrawingStatus {
    PENDING = 'PENDING',
    SELECTED = 'SELECTED',
    NOT_SELECTED = 'NOT_SELECTED',
}

export enum TicketStatus {
    TEMPORARY = 'TEMPORARY',
    EXPIRED = 'EXPIRED',
    CONFIRMED = 'CONFIRMED',
    CANCELED = 'CANCELED',
}

export enum WaitlistEntryStatus {
    WAITING = 'WAITING',
    OFFERED = 'OFFERED',
    EXPIRED = 'EXPIRED',
    PASSED = 'PASSED',
    RESERVED = 'RESERVED',
    REMOVED = 'REMOVED',
}

export enum CountryFilter {
    ALL = 'ALL',
    US = 'US',
    CA = 'CA',
    AU = 'AU',
    GB = 'GB',
    DE = 'DE',
    FR = 'FR',
    ES = 'ES',
    IT = 'IT',
    IE = 'IE',
    NL = 'NL',
    MX = 'MX',
}

export enum ActivityTypeNames {
    GAME_DEMO = 'GAME_DEMO',
    GENERAL_CHECK_IN = 'GENERAL_CHECK_IN',
    COMPETITIVE_PLAY = 'COMPETITIVE_PLAY',
    EXCLUSIVE_OFFER = 'EXCLUSIVE_OFFER',
    LEAD_GENERATION = 'LEAD_GENERATION',
    MEET_GREET = 'MEET_GREET',
    PRODUCT_LAUNCH = 'PRODUCT_LAUNCH',
    SPECIAL_EVENT = 'SPECIAL_EVENT',
    UNKNOWN = 'unknown',
    WORK_ONSITE = 'RTW',
}

export enum ConfigType {
    HERO_IMAGE_DISPLAY = 'HERO_IMAGE_DISPLAY',
    NINTENDO_SPONSORED = 'NINTENDO_SPONSORED',
    VIEWABLE_ONLINE = 'VIEWABLE_ONLINE',
    ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
    MAX_NUM_ATTENDEES = 'MAX_NUM_ATTENDEES',
    MIN_NUM_ATTENDEES = 'MIN_NUM_ATTENDEES',
    ACCESS_CODE_REQUIRED = 'ACCESS_CODE_REQUIRED',
    SEND_DRAWING_LOSER_EMAIL = 'SEND_DRAWING_LOSER_EMAIL',
    SEND_DRAWING_WINNER_EMAIL = 'SEND_DRAWING_WINNER_EMAIL',
    SEND_ENTRY_CONFIRMATION_EMAIL = 'SEND_ENTRY_CONFIRMATION_EMAIL',
    TICKET_TYPE = 'TICKET_TYPE',
    WAITLIST_ENABLED = 'WAITLIST_ENABLED',
    WAITLIST_END_DATE = 'WAITLIST_END_DATE',
    WAITLIST_START_DATE = 'WAITLIST_START_DATE',
}

export enum HeroImageEligibility {
    ELIGIBLE = 'ELIGIBLE',
    INELIGIBLE = 'INELIGIBLE',
    STICKY = 'STICKY',
}

export type Location = {
    location_id: string;
    name: string;
    description: string | null;
    address_line_1: string;
    address_line_2?: string;
    address_line_3?: string;
    city: string;
    region: string;
    hide_region: boolean;
    postal_code: string;
    country: string;
    time_zone: string;
};

export type Asset = {
    asset_id: string;
    type: string;
    storage_solution: string;
    path: string;
    tag: string;
    disclaimer: string | null;
};

export type GlobalState<T> = {
    value: T;
    setValue(newValue: T): void;
};

/* ALPS */
export interface AlpsErrorResponse {
    errorCode: string;
    message: string;
}

export interface AlpsSessionData {
    code: string;
    idToken: string;
    userId: string;
    nickname: string;
}

export interface JwtClaims {
    exp: number; // How long until the JWT expires
    user_id: string; // The primary participant identifier value
    name: string; // nickname
    sub: string; // participant_id
    user_image: string | null; // Image URL for this user. For example, their Mii
}

export interface AuthenticatedUser {
    userId: string;
    nickname: string;
}

/* NINTENDO ACCOUNT */
export interface AlpsUserData {
    getRawData(): any;
    getNNAId(): string | undefined;
    getUserId(): string;
    getNickname(): string;
    getGender(): 'unknown' | 'male' | 'female';
    getBirthday(): string | undefined;
    getAge(): number | null;
    getCountry(): string;
    getLanguage(): string;
    getRegion(): string | null;
    getEmailVerified(): boolean;
    getEmailOptedIn(): boolean;
    getEmailOptedInUpdatedAt(): number;
    isAllowedAnalyticsOptedIn(): boolean;
    getInternalAnalysisOptedIn(): boolean;
    getInternalAnalysisOptedInUpdatedAt(): number;
    getTargetMarketingOptedIn(): boolean;
    getTargetMarketingOptedInUpdatedAt(): number;
    getAnalyticsPermissions(): {
        [name: string]: {
            permitted: boolean;
            updatedAt: number;
        };
    };
    getClientFriendsOptedIn(): boolean;
    getIsChild(): boolean;
    getCoppaCertifiedParent(): boolean;
    getCreatedAt(): number;
    getUpdatedAt(): number;
}

export interface AlpsRequestOption {
    force: boolean;
}

export interface AlpsWindow {
    Alps: {
        Api: {
            addGlobalEventHandler(
                eventName: string,
                callback: (error: AlpsErrorResponse | null, result: any | null) => void,
            ): void;
            requestSessionData(
                callback?: ((data: AlpsSessionData | null) => void) | null,
                options?: AlpsRequestOption,
            ): Promise<AlpsSessionData>;
            showLogin(): ((data: AlpsSessionData | null) => void) | null;
        };
    };
    alpsClientInfo: {
        clientId: string | undefined;
        scope: Array<string> | string;
    };
}

export type ParticipantInfo = {
    FULL_NAME?: string;
    FIRST_NAME?: string;
    LAST_NAME?: string;
    POSTAL_CODE?: string;
    PHONE?: string;
    US_ADDRESS?: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        region: string;
        postalCode: string;
        country: string;
    };
    OPT_IN?: string;
    ACCESS_CODE?: string;
};

// Used for participant information on registration submission
export type ParticipantInfoMap = { [nintendoAccountId: string]: ParticipantInfo };

// Used for participant data collection (ie, opt-in) on registration submission.
export type ParticipantDataCollectionMap = { [nintendoAccountId: string]: ParticipantInfo };

export type NintendoAccountFamilyResponse = {
    family: Array<NintendoAccountFamilyUser>;
    eligibility: { [key: string]: boolean };
    participant_info: ParticipantInfoMap;
};

export type NintendoAccountFamilyUser = {
    mii_url: string;
    nickname: string;
    user_id: string;
    birthday: string;
    supervisor: boolean;
};

/* GENERAL */
export interface PaginatedResponse<T> {
    data: Array<T>;
    next_page: number | null;
    page_size: number;
    total_pages: number;
}

export interface Config {
    name: ConfigType;
    value: string;
}

/* Files */
export enum FileCategory {
    GENERAL_INFO = 'GENERAL_INFO',
    LEGAL = 'LEGAL',
    TOURNAMENT_INFO = 'TOURNAMENT_INFO',
}

export interface FileAssociation {
    file: File;
}

export interface File {
    file_id: number;
    asset_id: number;
    display_name: string;
    category: FileCategory;
    instructions: string | null;
    order: number;
    asset: Asset;
    file_configs?: Array<Config>;
}

/* EVENTS */
export interface EventResponse {
    event_id: number;
    name: string;
    description: string;
    details_copy: string;
    location: Location;
    image_asset: Asset;
    detail_image_asset?: Asset;
    start_date: string;
    end_date: string;
    audience: Audience;
    files: Array<FileAssociation>;
    event_configs?: Array<Config>;
    legal_disclaimer?: string | null;
    age_rating_asset?: Asset | null;
}

export interface EventActivityResponse extends EventResponse {
    activities: Array<ActivityResponse>;
}

/* REWARDS */

export interface RewardType {
    name: string;
    description: string;
    digital_type: string;
    has_value: boolean;
    is_digital: boolean;
    localization_key: string;
}

export interface Reward {
    reward_type: RewardType;
    name: string;
    value: string;
}

/* DRAWINGS */
export interface DrawingOutcome {
    drawing_id: string;
    outcome: string;
    reward: Reward;
}

export interface DrawingResponse {
    drawing_id: string;
    registration_start_date: string;
    registration_end_date: string;
    drawing_rules: string;
    status: string;
    max_attendees_per_ticket: number;
    drawing_outcomes: Array<DrawingOutcome>;
}

export interface EventGateResponse {
    event_gate_id: number;
    event_id: number;
    description: string;
    space_available: boolean;
    max_attendees_per_party: number;
    min_attendees_per_party: number;
    guest_rules: string;
    start_date: string;
    end_date: string;
    drawing_required: boolean;
    registration_start_date: string;
    registration_end_date: string;
    files: Array<FileAssociation>;
    event_gate_configs: Array<Config>;
}

export interface EventGateDrawing extends EventGateResponse {
    drawings: Array<DrawingResponse>;
}

export interface DataCollection {
    data_collection_id: number;
    attendee_type: AttendeeType;
    attribute_type: AttributeType;
    require_response: boolean;
    tooltip: string;
}

export interface DataCollectionAssociation {
    data_collection_id: number;
    data_collection: DataCollection;
}

export interface EventDataCollection extends DataCollectionAssociation {
    event_id: number;
}

export interface EventGateDataCollection extends DataCollectionAssociation {
    event_gate_id: number;
}

export interface ActivityDataCollection extends DataCollectionAssociation {
    activity_id: number;
}

export interface Ticket {
    ticket_id: string;
    participant_id: number;
    party_id: string;
    status: string;
    // Optional, the drawing_ticket.ticket_id that resulted in this ticket
    drawing_ticket_id: string | null;
    temporary_expires: string | null;
    waitlist_entry_id?: string | null;
}

export interface ConfirmTicketsRequest {
    tickets: Array<Ticket>;
    guests: Array<string>;
    // Participant information stored in Codex
    participantInfo: ParticipantInfoMap;
    // Participant data collection stored in GPS
    participantDataCollection?: ParticipantDataCollectionMap;
    cancelTicketId?: string;
    addMeToParty?: boolean;
}

export enum AttendeeType {
    HOST = 'HOST',
    ADULT = 'ADULT',
    CHILD = 'CHILD',
}

export enum AttributeType {
    FULL_NAME = 'FULL_NAME',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    POSTAL_CODE = 'POSTAL_CODE',
    PHONE = 'PHONE',
    US_ADDRESS = 'US_ADDRESS',
    OPT_IN = 'OPT_IN',
    ACCESS_CODE = 'ACCESS_CODE',
}

export enum AcknowledgementAttributeType {
    // Consent acknowledgement checkboxes
    ADULT_ACKNOWLEDGEMENT = 'ADULT_ACKNOWLEDGEMENT',
    CHILD_ACKNOWLEDGEMENT = 'CHILD_ACKNOWLEDGEMENT',
}

export enum AcknowledgementType {
    CHECKBOX = 'CHECKBOX',
    NO_REQUIREMENT = 'NO_REQUIREMENT',
}

/* ACTIVITY */

export interface ActivityResponse {
    activity_id: number;
    activity_type: ActivityType;
    registration_start_date: string;
    registration_end_date: string;
    time_slots: Array<TimeSlot>;
    drawing: boolean;
    drawing_rules: string;
    name: string;
    description: string;
    location: Location;
    asset: Asset;
    start_date: string;
    end_date: string;
    audience: Audience;
    files: Array<FileAssociation>;
    activity_configs: Array<Config>;
}

export interface ActivityType {
    activity_type_id: number;
    name: ActivityTypeNames;
    icon_class: string;
    description: string;
    asset: Asset;
}

export interface TimeSlot {
    time_slot_id: number;
    activity_id: number;
    drawing_id?: string;
    space_available: boolean;
    max_attendees_per_party: number;
    start_date: string;
    end_date: string;
}

export interface WaitlistEntryRequest {
    event_id: number;
    resource_type: string;
    resource_id: string;
    sms_opted_in_status: boolean;
    adults_requested: number;
    children_requested: number;
}

export interface WaitlistEntry {
    waitlist_entry_id: string;
    status: WaitlistEntryStatus;
    participant_id: number;
    event_id: number | string;
    resource_type: string;
    resource_id: string | number;
    host_ticket_id: string | null;
    party_id: string | null;
    offered_date: string | null;
    redeemed_date: string | null;
    offer_expire_date: string | null;
    sms_opted_in_status: boolean;
    adults_requested: number;
    children_requested: number;
}

export interface HydratedWaitlistEntry extends WaitlistEntry {
    event: EventActivityResponse;
    /** ie: event_gate */
    resource: EventGateResponse;
}

// ROUTE PARAMS
export interface ActivityRouteParams extends Params {
    eventId: string;
    activityId: string;
}

export interface ReservationRouteParams extends ActivityRouteParams {
    timeSlotId: string;
}

/* RESERVATIONS */
export interface Reservation {
    reservation_id: string;
    unique_id: string;
    participant_id: number;
    party_id: string;
    activity_id: string;
    time_slot: TimeSlot;
    status: Status;
    reserved_date: string;
    temporary_reservation_key: string;
    temporary_reservation_expires: string;
}

export interface ReservationAddToCalendar {
    type: string;
    value: string;
}

/* MyActivity */

export interface MyActivityResponse extends ActivityResponse {
    reservations: Array<Reservation>;
    event: {
        name: string;
    };
}

export interface MyRSVPsRerouteState {
    editTitle: string;
    reservation?: Reservation;
    entryTitle?: string;
}

// Route state for forwarding to the MyTicketsAndPasses component
// If toastData exists, a toast will be shown on the MyTicketsAndPasses component
export interface MyTicketsRerouteState {
    displayTab?: MyTicketTab;
    refreshMyTicketsOnLoad?: boolean;
}

export interface MyTicketsRouteLocation {
    pathname: string;
    state: MyTicketsRerouteState;
}

/* Localization */
export enum SupportedLocale {
    DE_DE = 'de-DE',
    EN_US = 'en-US',
    EN_GB = 'en-GB',
    EN_AU = 'en-AU',
    ES_ES = 'es-ES',
    ES_MX = 'es-MX',
    FR_FR = 'fr-FR',
    FR_CA = 'fr-CA',
    IT_IT = 'it-IT',
    NL_NL = 'nl-NL',
}

export interface AuthenticationState {
    authenticating: boolean;
    accountInfo: AccountInfo;
    setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo>>;
    authServiceError: boolean;
    navBarUser: JSX.Element;
}

/* Error */
export interface ErrorModalMessage {
    title: string;
    body: string;
}

export interface GrandPrixServiceError<T = unknown> {
    code: string;
    message: string;
    details: T;
}

export enum MemberRoleType {
    HOST = 'HOST',
    GUEST = 'GUEST',
}

/**
 * Ticket enums
 */

export enum TicketResource {
    EVENT_GATES = 'event_gates',
    DRAWINGS = 'drawings',
    TIME_SLOTS = 'time_slots',
}

export interface TicketResourceIdentifier {
    resourceType: TicketResource;
    resourceId: string | number;
}

// Used for selecting which tab is active under the My Ticket / Passes page
export enum MyTicketTab {
    EVENT_GATE = 'EVENT_GATE',
    WARP_PIPE_PASS = 'WARP_PIPE_PASS',
    WAITLIST = 'WAITLIST',
}

// Response from GPS where the TicketTypeResponse is in TicketDetails.type
export enum TicketTypeResponse {
    EVENT_GATE_TICKET = 'event_gate_ticket',
    DRAWING_TICKET = 'drawing_ticket',
    TIME_SLOT_TICKET = 'time_slot_ticket',
}

/**
 * Ticket types
 */

export interface ParticipantIdentification {
    type: string;
    value: string;
}

export interface Consent {
    // Determines if this party member signed all the required consents or not
    // If not defined, there was nothing to consent to
    completed?: boolean;
}

export interface PartyMember {
    participant_id: number;
    role: MemberRoleType;
    mii: string | null;
    nickname: string | null;
    birthday: string | null;

    // The My Tickets API call foregoes the data from Codex
    // If you need this data, you will need to fetch it and
    // merge
    participant_info?: ParticipantInfo;
    consents: Consent;
    participant_identification: ParticipantIdentification;
    ticket: Ticket;
}

export interface Party {
    party_id: string;
    host_participant_id: number;
    members: Array<PartyMember>;
}

export interface TicketMetaData {
    event_id: number;
    event_name: string;
    activity_id?: number | null;
    activity_name?: string | null;
    location: Location;
    name?: string | null;
}

export interface TicketDetails {
    type: TicketTypeResponse; // one of TicketTypeResponse enums
    value: string; // The unique DB ID for this TicketTypeResponse
    // start_date and end_date are different based on the TicketDetails.type
    // For example, for TIME_SLOT_TICKET, dates are for the time slot while
    // DRAWING_TICKET, dates are for the drawing
    start_date: string;
    end_date: string | null;
}

export interface MetaData {
    meta_data: TicketMetaData;
}

export interface DrawingStatusMixin {
    drawing_status: DrawingStatus;
}

export interface Outcome {
    // Using TS intersection (&) to include MetaData
    details: TicketDetails & MetaData;
}

// The top level DrawingTicket does not have MetaData. However the outcomes do.
export interface DrawingTicket {
    party: Party;
    details: TicketDetails & DrawingStatusMixin;
    outcomes: Array<Outcome>;
}

// All the rest of our Ticket types should be a BaseTicket
export interface BaseTicket {
    details: TicketDetails & MetaData;
    party: Party;
}

// Not adding any additional properties, but helpful if we need to be clear about what
// BaseTicket type this is. Usually we will just use BaseTicket for everything that isn't
// a DrawingTicket
export interface EventGateTicket extends BaseTicket {}
export interface TimeSlotTicket extends BaseTicket {}

export interface ParticipantTerms {
    attributes?: Array<DataCollectionAssociation>; // The attributes defined for data collection
    participant_info?: ParticipantInfo; // The data we already have from Codex
    consent_files: Array<File>; // The files that need to be consented to
}

export enum LocalizedErrorMessages {
    GP0009 = 'GP0009',
    GP0010 = 'GP0010',
    GP0011 = 'GP0011',
    GP0013 = 'GP0013',
    GP0014 = 'GP0014',
    GP0015 = 'GP0015',
    GP0016 = 'GP0016',
    GP0243 = 'GP0243',
    GP0244 = 'GP0244',
    GP0252 = 'GP0252',
}

/**
 * Must be used with GrandPrixService error type. For example: GrandPrixServiceError<DetailFormErrors>
 *
 * Here is an example GrandPrixServiceError from GPS that uses this format for its "details" object.
 *
 * {
 *     "code": "GP00425",
 *     "message": "Access code is invalid",
 *     "details": {
 *         "b3b747b348e7421a": {
 *             "ACCESS_CODE": "21A49E875CCD7440"
 *         }
 *     }
 * }
 *
 * In this case the first key is a nintendo account id, "b3b747b348e7421a" and the object it points to says that the
 * AttributeType.ACCESS_CODE, "21A49E875CCD7440" is invalid.
 */
export interface DetailFormErrors {
    [accountId: string]: {
        [attributeType in AttributeType]: string;
    };
}
