// French Canadian (Language-Country Code: fr-CA) Locale File.

import defaultsDeep from 'lodash.defaultsdeep';

import { PublicLocalizationObject } from './en_US';
import fr_FR from './fr_FR';

const fr_CA: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            currentLanguage: 'Français (Canada)',
        },

        tabs: {
            myTicketsAndPasses: 'Mes billets / passes',
        },
        events: {
            dataAttribute: {
                MAILING_ADDRESS: {
                    state: 'Province',
                },
                OPT_IN: "Consentir au partage d'adresse courriel",
            },
            ageRatingUrl: 'https://www.esrb.org/ratings-guide/fr/',
        },
        activities: {
            details: {
                alreadyRSVPd: 'Vous possédez déjà une passe pour cette activité.',
            },
        },
        reservations: {
            event_registration: {
                currentUserIneligible:
                    'Vous êtes déjà inscrit(e) pour une passe donnant accès à cette activité ou à une autre activité au même moment.',
                removedGuests:
                    "{guests} a été retiré(e) de la liste d'invités car il/elle est déjà inscrit(e) pour une passe donnant accès à cette activité ou à une autre activité au même moment.",
            },
        },
        tickets: {
            backToMyTicketsPasses: 'Retour à Mes billets / passes',
            cancelTicket: {
                cancelTicketModalWarning: {
                    line2: 'Votre billet et votre passe seront annulés.',
                    line3: "L'annulation de votre billet annulera automatiquement les billet(s) et passe(s) pour tous les membres de votre groupe (le cas échéant). Tout le monde recevra un courriel de confirmation d'annulation.",
                    line4: "Toutes les annulations sont définitives. Les billets et les passes ne seront pas honorés en cas d'annulation.",
                },
            },
            cancelPass: {
                cancelTicketModalTitle: 'Annuler une passe',
                cancelLink: 'Annuler cette passe',
                areYouSure: 'Voulez-vous vraiment annuler cette passe ?',
                cancelButton: 'Annuler une passe',
            },
            cancelDrawingEntry: {
                cancelTicketModalWarning: {
                    line2: "L'annulation de votre participation au tirage annulera automatiquement les participations de tous les membres de votre groupe (le cas échéant). Tout le monde recevra un courriel de confirmation d'annulation.",
                },
            },
            filter: {
                ticketTypes: {
                    FAMILY: 'Billets familiaux',
                },
            },
        },
        error: {
            serviceError: {
                GP0252: 'Vous possédez déjà une passe pour cette activité.',
            },
            timeSlotCancelingError: "Erreur lors de l'annulation de la passe",
        },
        account: {
            checkIn: {
                poorInternetConnectivity:
                    "En cas de mauvaise connexion internet lors de l'événement, nous vous recommandons d'imprimer cette passe soit à partir de cette page, soit à partir du courriel que nous vous avons envoyé à l'adresse courriel associée à votre compte Nintendo. (Si vous ne voyez pas ce courriel, veuillez vérifier votre dossier de courrier indésirable).",
            },
            passes: {
                warpPipePass: 'Passe',
                warpPipePasses: 'Passe',
            },
            rsvps: {
                rsvps: 'Billets et Passes',
                viewPass: 'Voir vos passes',
                warpPipePassAdded: 'Vous avez bien reçu votre passe pour {ticketName}.',
                details: {
                    pass: {
                        ios: require('../images/pass/ios/fr/apple_wallet_badge_ca.svg'),
                    },
                },
            },
        },
        noneFound: {
            warpPipePasses: 'Vous ne possédez actuellement aucune passe active',
        },
        footer: {
            disclaimer:
                "Les passes et/ou billets sont à usage unique, non transférables et ne peuvent pas être vendus. Les passes et/ou billets peuvent être échangeables contre un accès prioritaire à la file d'attente d'un jeu ou d'un événement sélectionné à une heure donnée et ne pas être échangeables s'ils sont utilisés à une heure différente. QR Code est une marque déposée de DENSO WAVE INCORPORATED. Nintendo trademarks are properties of Nintendo. © Nintendo.",
            privacyPolicyUrl: 'https://www.nintendo.com/fr-ca/privacy-policy/',
            termsOfUse: "Conditions d'utilisation",
            termsOfUseUrl: 'https://www.nintendo.com/fr-ca/terms-of-use/',
        },
        dates: {
            hourMinute: 'HH [h] mm [HE]',
            hourMinutesTimezone: 'HH [h] mm [HE] zz',
            reservationTime: 'HH [h] mm [HE]',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'HH [h] mm [HE] z',
                timeSlotHourMinute: 'HH [h] mm [HE]',
            },
            eventTickets: {
                same_day_time: 'HH [h] mm [HE]',
                time: 'HH [h] mm [HE]',
                cancel: {
                    time: 'dddd MMM D, YYYY - HH [h] mm [HE]',
                },
                waitlistClaimBy: 'H [h] mm [HE] [le] dddd DD MMMM',
            },
        },
    },
    fr_FR,
);

export default fr_CA;
