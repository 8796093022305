import { useContext } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';

import { SupportedLocale } from '../../utils/types';
import LanguageContext from '../../context/LanguageContext';
import { getSupportedLocale } from '../../utils/LanguageUtils';
import { isInternal } from '../../utils/InternalUtils';
import LocalizationContext from '../../context/LocalizationContext';

export enum FormatSpecialCase {
    /* Indicates to not do any post-processing to the localized date. */
    NONE = 'NONE',
    /* Indicates that the localized date should have any and all words uppercased. */
    UPPERCASE = 'UPPERCASE',
    /* Indicates that the localized date is in the middle of a sentance, and locale formatting should be considered. */
    MIDSENTENCE = 'MIDSENTENCE',
}

type Props = {
    /** The raw date string. */
    date: string;
    /** The format to display the date in. */
    format: string;
    /** The timezone relative to the date. */
    timezone: string;
    /** Value that indicates how the string should be processed afterwords. */
    specialCase?: FormatSpecialCase;
};

/**
 * Utility-like component that renders a date according to the end user's language and locale.
 */
const LocalizedDate = (props: Props) => {
    const { locale } = useContext(LanguageContext);
    const localizedStrings = useContext(LocalizationContext);
    const { date, format, timezone, specialCase } = props;

    let supportedLocale: SupportedLocale = getSupportedLocale(locale);

    // If internal, fix it to English so that we don't have moment
    // render dates in other locales.
    if (isInternal(localizedStrings)) {
        supportedLocale = SupportedLocale.EN_US;
    }

    // If no format was provided, return an empty string. Otherwise this will print out the raw
    // date string.
    if (!format) {
        return '';
    }
    const formattedDate = moment(date).locale(supportedLocale).tz(timezone).format(format);

    switch (specialCase) {
        case FormatSpecialCase.UPPERCASE:
            return formattedDate.toUpperCase();

        case FormatSpecialCase.MIDSENTENCE:
            // NOTE: This will consider dddd and ddd. At this time, we do not have any cases of
            // 'dddd' in the middle of a sentance. If this is needed, we'll need to expand the logic
            // and seperate the two.
            if (
                format.startsWith('ddd') &&
                localizedStrings.dates.formatting.lowerCaseDayOfWeekMidSentence
            ) {
                return formattedDate.charAt(0).toLowerCase() + formattedDate.slice(1);
            }

            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

        default:
            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }
};

export default LocalizedDate;
