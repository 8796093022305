// Spanish (Language-Country Code: es-ES) Locale File.
import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const es_ES: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            backToActivityTimes: 'Volver al horario de la actividad',
            backToActivityDetails: 'Volver a la información de la actividad',
            backToEvent: 'Volver al evento',
            location: 'Ubicación',
            rsvp: '',
            entry: '',
            soldOut: 'NO QUEDAN PLAZAS',
            cancel: 'Cancelar',
            close: 'Cerrar',
            confirm: 'Confirmar',
            exit: '',
            signInCreateAccount: 'Iniciar sesión / Crear cuenta',
            signIn: 'Iniciar sesión',
            warpPipePass: '',
            learnMore: 'Más información',
            currentLanguage: 'Español (España)',
            yes: '',
            no: '',
            note: 'NOTA',
            addressFormat: '{address}, {city}, {region} {postal_code}',
            addressFormatHideRegion: '{address}, {city} {postal_code}',
            areYouSure: '¿Seguro que quieres continuar?',
        },
        tabs: {
            events: 'Eventos',
            myRSVPs: '',
            myTicketsAndPasses: 'Mis entradas / Pases',
            checkIn: '',
            signIn: '',
        },
        events: {
            eventItemHeader: 'Evento:',
            upcoming: '',
            moreInfo: 'Más información',
            eventDetails: 'Información del evento',
            viewEventDetails: 'Ver información del evento',
            details: {
                tabs: {
                    tickets: 'Entradas',
                    activities: 'Actividades',
                    resources: '',
                },
                accessCode: {
                    aria: {
                        accessCodeInput: 'Código de acceso',
                        exitButton: 'Salir de la pantalla del código de acceso',
                    },
                    title: 'Introduce tu código de acceso',
                    toggleButtonLabel: 'Canjear un código de acceso',
                    redeemButtonLabel: 'Canjear',
                    inputPlaceHolder: 'Código de acceso de 16 dígitos',
                    enterDifferentCode: 'Introducir un código de acceso diferente',
                    success: {
                        woohoo: '¡Genial!',
                        youCanRegister: 'Ya puedes registrarte para:',
                    },
                    error: {
                        invalid: 'El código no es válido. Inténtalo de nuevo.',
                        registrationClosed: 'El registro para esta entrada ha concluido.',
                    },
                },
                waitlist: {
                    available: 'Lista de espera disponible:',
                    drawingOver:
                        'La rifa ha concluido, pero ¡quizá todavía tengas una oportunidad de asistir al evento! Apúntate en la lista de espera y te informaremos si se queda alguna plaza libre. Ten en cuenta que apuntarte en la lista de espera no garantiza que consigas entradas.',
                    drawingLost:
                        'Tu participación para conseguir entradas no ha resultado seleccionada. Si quieres, puedes apuntarte en la lista de espera y te informaremos si se queda alguna plaza libre. Ten en cuenta que apuntarte en la lista de espera no garantiza que consigas entradas.',
                    waitlistJoined:
                        'Te has apuntado en la lista de espera de este evento: {eventName}',
                    waitlistLink: 'Ver tu lista de espera >',
                    waitlistOffer:
                        '¡Genial! Una plaza/varias plazas han quedado libres en una de las listas de espera en las que te has apuntado. Tienes hasta el {monthDay} a las {time} para solicitarlas; si no lo haces, se las ofreceremos a la siguiente persona en la lista de espera.',
                    offerPassed:
                        'Has declinado recibir {ticketName}. Si quieres, puedes apuntarte de nuevo en la lista de espera,',
                    offerExpired:
                        'Ha caducado la posibilidad de recibir {ticketName}. Si quieres, puedes apuntarte de nuevo en la lista de espera,',
                    waitlistClosed: 'La lista de espera se ha cerrado.',
                    button: {
                        join: 'Apuntarse en la lista de espera',
                        edit: 'Editar tu lista de espera',
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Nombre completo',
                FIRST_NAME: 'Nombre',
                LAST_NAME: 'Apellido(s)',
                POSTAL_CODE: 'Código postal',
                PHONE: 'Número de teléfono',
                US_ADDRESS: 'Dirección',
                MAILING_ADDRESS: {
                    streetAddress: 'Calle',
                    aptOrSuite: 'Piso',
                    city: 'Ciudad',
                    state: 'Comunidad autónoma/Región',
                    country: 'País',
                    zipCode: 'Código postal',
                },
                OPT_IN: 'Dar permiso para compartir el correo electrónico',
                ACCESS_CODE: 'Código de acceso',
            },
            ageRatingUrl: 'https://pegi.info/',
        },
        activities: {
            activities: 'Actividades',
            viewActivities: 'Ver actividades',
            registrationNotBegun: 'Aún no es posible registrarse para esta actividad.',
            registrationHasEnded: 'El registro para esta actividad ha concluido.',
            registrationBegins: 'El registro comienza el',
            details: {
                title: 'Info de la actividad',
                registrationBegins: 'El registro comienza:',
                registrationEnds: 'El registro termina:',
                header: {
                    noAvailableTimes: 'Lo sentimos, pero no hay horas disponibles.',
                },
                continueToRSVP: 'Continuar',
                viewMy: 'Ver mi {0}',
                alreadyRSVPd: 'Ya tienes un Warp Pipe Pass para esta actividad.',
                ticketIsRequired:
                    'Se requiere tener una entrada para este evento antes de registrarse para esta actividad. {link}.',
                eventTicketRequired: 'Se requiere tener una entrada de evento',
                ticketIsPendingDrawing:
                    'Tu entrada para el evento todavía depende de una rifa. Vuélvete a pasar por aquí cuando la rifa haya tenido lugar.',
                checkForTicketAvailability: 'Comprueba aquí la disponibilidad de entradas',
                label: {
                    startDate: 'La fecha de comienzo es el {startDate}',
                    activityType: 'El tipo de actividad es: {activityType}',
                },
                iconLabel: {
                    activityTime: 'Icono del horario de la actividad',
                    activityType: 'Icono del tipo de actividad',
                    registration: 'Icono del tipo de registro',
                    location: 'Icono de la ubicación',
                },
            },
            timeSlots: {
                title: '',
                noTimesForDay: 'No hay horas disponibles para esta fecha.',
            },
            unknownActivityType: '',
            types: {
                GAME_DEMO: 'Demostración de un juego',
                GENERAL_CHECK_IN: 'General',
                COMPETITIVE_PLAY: 'Juego competitivo',
                EXCLUSIVE_OFFER: 'Oferta exclusiva',
                LEAD_GENERATION: 'Establecimiento de contactos',
                MEET_GREET: 'Encuentro especial',
                PRODUCT_LAUNCH: 'Lanzamiento de un producto',
                SPECIAL_EVENT: 'Evento especial',
                unknown: '',
            },
        },
        reservations: {
            timeRemainingForReservation:
                'Tiempo restante: {timeRemaining}. Si abandonas esta página o se agota el tiempo antes de que completes el proceso de registro, se cancelará esta transacción.',
            timeRemainingForDrawing:
                'Tienes {timeRemaining} para completar tu participación en esta rifa.',
            timeRemainingForWaitlist:
                '¡Genial! Se ha(n) quedado disponible(s) {numTickets} {ticketName} en la lista de espera en la que te has apuntado. Si quieres asistir al evento, acepta la(s) entrada(s) antes de que se agote el tiempo: {timeRemaining}',
            waitlistThingsToKeepInMind: 'Algunos detalles para tener en cuenta:',
            waitlistThingsToKeepInMindItem1:
                'Si aceptas estas entradas, se te quitará de la lista de espera para cualquier otra entrada del siguiente evento: {eventName}.',
            waitlistThingsToKeepInMindItem2:
                'Si abandonas esta página, no perderás la oportunidad de aceptar la(s) entrada(s). Podrás aceptarla(s) hasta la fecha designada para ello.',
            waitlistThingsToKeepInMindItem3:
                'Si no deseas asistir al evento, puedes declinar las entradas. Así quedarán a disposición de la siguiente persona en la lista de espera.',
            waitlistThingsToKeepInMindItem4:
                'Declinar las entradas no afectará a ninguna otra lista de espera en la que te hayas apuntado para el siguiente evento: {eventName}. También podrás volverte a apuntar en la lista de espera más adelante.',
            minutes: ' minutos',
            registrant: '',
            registrantInformation: 'Información sobre tu registro',
            nickname: 'Apodo de la cuenta Nintendo',
            confirmRSVP: 'Confirmar',
            confirmEntry: 'Participar en la rifa',
            legalConsent: 'Consentimiento legal',
            adultAcknowledgement:
                'Al seleccionar esta casilla, certifico que yo y el/los invitados mayores de 18 años que forman parte de mi grupo familiar hemos leído la política de privacidad y hemos aceptado los términos y condiciones que se enlazan más abajo.',
            childAcknowledgement:
                'Al seleccionar esta casilla, certifico que soy el padre/madre/tutor del o de los invitados menores de 18 años que forman parte de mi grupo familiar. Asimismo, acepto en su nombre la política de privacidad y los términos y condiciones que se enlazan más arriba.',
            adultGuestsAcknowledgement:
                'Al seleccionar esta casilla, confirmo que tengo el permiso de mis invitados adultos para añadirlos como invitados para este evento y para que reciban una invitación por correo electrónico. Cada invitado adulto debe aceptar los términos y condiciones incluidos en el correo electrónico de invitación antes de asistir al evento.',
            event_registration: {
                attendees: 'Asistentes',
                note: 'NOTA:',
                attendeeCannotRegister:
                    'Los asistentes no pueden registrarse para más de una actividad a la misma hora, ni para una misma actividad más de una vez al día.',
                currentUserIneligible:
                    'Ya te has registrado para un Warp Pipe Pass para esta actividad u otra actividad distinta a la misma hora.',
                removedGuests:
                    'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para un Warp Pipe Pass para esta actividad u otra actividad distinta a la misma hora.',
                selectAllAttendees: 'Selecciona a todos los asistentes de esta actividad.',
            },
        },
        tickets: {
            ticket: 'entrada',
            tickets: 'entradas',
            wppRequiredForCheckIn: 'Se requiere este {0} para registrarse.',
            wppsRequiredForCheckIn: 'Se requieren estos {0} para registrarse.',
            ticketRequiredForCheckIn: 'Se requiere esta {0} para asistir al evento.',
            ticketsRequiredForCheckIn: 'Se requieren estas {0} para asistir al evento.',
            getTickets: 'Obtener entradas',
            eventTicket: 'entrada de evento',
            eventTickets: 'entradas de evento',
            viewTickets: 'Ver entradas',
            viewEntry: 'Ver participación',
            registrationClosed: 'El registro ha concluido',
            comingSoon: 'Próximamente',
            cancelOtherTicket: 'Cancela tu otra entrada antes de registrarte para esta.',
            soldOut: 'No quedan plazas',
            adultRequired: 'Se requiere la presencia de un adulto',
            adultRequiredTooltip: 'Solo los adultos pueden registrarse para este evento.',
            adultRequiredActivityTooltip:
                'Solo los adultos pueden registrarse para esta actividad.',
            backToMyTicketsPasses: 'Volver a mis entradas / pases',
            registrationAlreadyClosed: 'El registro concluyó el {0} a esta hora: {1}',
            registrationNotStarted: 'El registro se abre el {0} a esta hora: {1}',
            notFound: 'No se han encontrado entradas',
            unknownTicketType: 'Tipo de entrada desconocido',
            consent: {
                consentRequiredTooltipTitle: 'Se requiere consentimiento',
                consentRequiredTooltipBody:
                    'Este usuario debe iniciar sesión en su cuenta Nintendo para aceptar la invitación y aceptar los términos.',
                consentRequiredToolTipBodyHostUrl: 'aceptar la invitación del evento',
                consentRequiredToolTipBodyHost:
                    'Debes {0} y aceptar los términos para poder asistir a esta actividad.',
            },
            receivedTickets: 'Has recibido tus entradas para {resourceName} {resourceDates}.',
            drawings: {
                wonDrawing: 'Has ganado la rifa de entradas de {resourceName} {resourceDates}.',
                lostDrawing: 'Lo sentimos, pero no has ganado la rifa para esta entrada.',
                pendingDrawing:
                    'Te has registrado para participar en la rifa de entradas de {resourceName} {resourceDates}.',
            },
            registration: {
                ticketDetails: 'Información de la entrada',
                registrant: {
                    onlyOneActivity:
                        '{note} Los asistentes no pueden registrarse para más de una actividad a la misma hora, ni para una misma actividad más de una vez al día.',
                    dataNotice:
                        'Para obtener más información sobre cómo gestionamos tus datos, consulta nuestra {link}.',
                    requiredFields: 'Los campos marcados con * son obligatorios.',
                },
                guest: {
                    guest: 'Invitado/a',
                    guests: 'Tus invitados (máximo de {maxGuests})',
                    birthday: 'Fecha de nacimiento',
                    addManageGuests: 'Añadir/Gestionar invitados',
                    manageNintendoAccountFamily:
                        'Gestionar el grupo familiar de la cuenta Nintendo',
                    guestsRemovedTicket:
                        'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para una entrada de este evento.',
                    guestsRemovedDrawing:
                        'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para participar en la rifa de entradas de este evento.',
                    maxLimit: 'El número máximo de invitados permitido es {max}.',
                    guestFamilyHelp: '¿Aún no tienes un grupo familiar? {guestFamilyHelpLink}',
                    guestFamilyHelpLink: 'Descubre cómo crear y gestionar uno.',
                    guestFamilyHelpUrl:
                        'https://www.nintendo.com/es-es/Ayuda/Nintendo-Switch/Como-aniadir-miembros-a-un-grupo-familiar-1406409.html',
                    guestFamilyWarning:
                        'Solo se pueden añadir invitados desde tu {nintendoFamilyLink}. Si no ves a tus invitados en la lista, añádelos primero a tu grupo familiar de la cuenta Nintendo.',
                    guestFamilyLink: 'Grupo familiar de la cuenta Nintendo',
                    guestFamilyUrl:
                        'https://www.nintendo.com/es-es/Ayuda/Nintendo-Switch/Como-aniadir-miembros-a-un-grupo-familiar-1406409.html',
                    guestRemoveButtonLabel: 'Quitar a {user} del grupo',
                    selectFamilyMember:
                        'Seleccionar entre los miembros disponibles del grupo familiar de la cuenta Nintendo',
                    counter: 'Invitados seleccionados: {current} / {max}',
                    child: 'Niño/a',
                    notSelectableIf: 'No se puede seleccionar a un miembro del grupo familiar si',
                    reachedMaxGuests: '1. Has alcanzado el número máximo de invitados.',
                    childAndNotParent:
                        '2. El invitado es un niño/a y no eres su padre/madre/tutor.',
                    alreadyRegistered: '3. Ya se ha registrado.',
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Cancelar entrada',
                cancelLink: 'Cancelar esta entrada',
                cancelTicketModalWarning: {
                    line1: 'No podrás asistir a este evento sin entrada.',
                    line2: 'Se cancelarán tu entrada y tu Warp Pipe Pass(es)',
                    line3: 'Al cancelar tu entrada, se cancelarán automáticamente la entrada o entradas y el o los Warp Pipe Pass(es) de todos los miembros de tu grupo (si corresponde). Todos los miembros recibirán un correo electrónico en el que se les notificará sobre la cancelación.',
                    line4: 'Las cancelaciones son definitivas. Las entradas y los Warp Pass Passes no se considerarán válidos si se cancelan.',
                },
                areYouSure: '¿Seguro que quieres cancelar esta entrada?',
                imSure: 'Sí',
                cancelButton: 'Cancelar entrada',
                cancelYourTicket: '¿No puedes asistir? Pues cancela tu entrada o entradas',
            },
            cancelPass: {
                cancelTicketModalTitle: 'Cancelar Warp Pipe Pass',
                cancelLink: 'Cancelar este Warp Pipe Pass',
                areYouSure: '¿Seguro que quieres cancelar este Warp Pipe Pass?',
                imSure: 'Sí',
                cancelButton: 'Cancelar Warp Pipe Pass',
            },
            cancelDrawingEntry: {
                cancelTicketModalTitle: 'Cancelar participación',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Ya no participarás en la rifa de entradas.',
                    line2: 'Al cancelar tu participación en la rifa, se cancelará automáticamente la participación de todos los miembros de tu grupo (si corresponde). Todos los miembros recibirán un correo electrónico en el que se les notificará sobre la cancelación.',
                    line3: '',
                    line4: '',
                },
                areYouSure: '¿Seguro que quieres cancelar tu participación?',
                imSure: 'Sí',
                cancelButton: 'Cancelar participación',
            },
            waitlist: {
                waitlist: 'Lista de espera',
                title: 'Apuntarse en la lista de espera de: {eventName}',
                editTitle: 'Editar la lista de espera de: {eventName}',
                subtitle: '¡Te avisaremos en cuanto quede alguna plaza libre!',
                notice: {
                    line1: '{notice}: Las entradas se ofrecerán por orden de llegada a las personas que las soliciten, según el momento en el que se apuntaron en la lista de espera, y según también el número de entradas solicitado y las entradas que se encuentren disponibles.',
                    line2: 'Solo podrás recibir un ofrecimiento de entradas cada vez.',
                    line3: 'Tendrás {timeFrame} para registrarte para el evento antes de que caduque el ofrecimiento.',
                    line4: 'Si aceptas un ofrecimiento, se te quitará de la lista de espera para cualquier entrada restante.',
                    notice: 'Atención',
                    timeFrame: '36 horas',
                },
                individualTicketsHeader: 'Entradas individuales',
                familyTicketsHeader: 'Entradas familiares',
                guests: 'Invitados',
                individual: 'Individual',
                family: 'Familiar',
                adultGuests: 'Invitados adultos (mayores de 18 años)',
                childGuests: 'Invitados infantiles (entre 7 y 17 años)',
                guestCount: '{currentGuests}/{maxGuests} invitados(s) seleccionado(s)',
                select: 'Selecciona la o las listas de espera en las que quieres apuntarte',
                selectAll: 'Selecciona todas las entradas de tipo "{waitlistType}"',
                submit: 'Apuntarse en la lista de espera',
                summary: 'Resumen',
                summaryRequired: 'Debes aceptar los términos para apuntarte en la lista de espera.',
                summaryCheckbox:
                    'Al seleccionar esta casilla, entiendo que apuntarme en la lista de espera no garantiza que reciba entradas. Si se presenta una oportunidad, tendré 36 horas para aceptar las entradas.',
                summaryFooter:
                    'Estás a punto de enviar una solicitud para apuntarte en la lista de espera del siguiente evento: {eventName}. Recibirás un aviso por correo electrónico en el caso de que podamos ofrecerte entradas. Para asegurarte de que sigues recibiendo nuestros correos electrónicos, añade nuestra dirección de correo electrónico ({emailAddress}) en tu libreta de direcciones. También revisa de vez en cuando la carpeta de spam. Tendrás 36 horas para aceptar las entradas ofrecidas.',
                summaryEmail: 'no-reply@noa.nintendo.com',
                onTheWaitlist: 'Te has apuntado en la lista de espera:',
                leaveWaitlists: 'Abandonar las listas de espera',
                leaveWaitlist: 'Abandonar la lista de espera',
                makeChanges: 'Hacer cambios',
                cancelEntry: 'Cancelar solicitud',
                onWaitlistRequestingTickets:
                    'En la lista de espera (para {count} entrada(s) de evento)',
                confirmRemoveSelfFromWaitlistsForEvent:
                    'Estás a punto de quitarte de las listas de espera para el siguiente evento: {eventName}. Si lo haces, no recibirás ninguna notificación en el caso de que se queden más entradas disponibles.',
                confirmRemoveSelfFromWaitlist:
                    'Al cancelar tu solicitud, te quitarás de la lista de espera para siguiente evento: {ticketName}. Si lo haces, no recibirás ninguna notificación en el caso de que se queden más entradas disponibles.',
                stayOnWaitlists: 'Permanecer en las listas de espera',
                stayOnWaitlist: 'Permanecer en la lista de espera',
                noteOtherWaitlistsUnaffected:
                    'Ten en cuenta que esto no afectará a cualquier otra lista de espera en la que te hayas apuntado.',
                congratulationsTicketWaitlistOffered:
                    '¡Enhorabuena, podemos ofrecerte entradas para el siguiente evento: {eventName}! Acéptalas antes del {claimByDate} para asegurarte tu plaza.',
                congratulationsEventTicketWaitlistOffered:
                    '¡Enhorabuena, podemos ofrecerte entradas! Amplía para ver más detalles.',
                claimTickets: 'Aceptar entradas',
                passOnTickets: 'Declinar entradas',
                areYouSureYouDontWantIt: '¿Seguro que no quieres aceptar?',
                passOnTicketsNotice:
                    'Al declinar este ofrecimiento, no podrás aceptar las entradas que te ofrecemos y se te quitará de la lista de espera.',
                keepOffer: 'Aceptar el ofrecimiento',
                passOnOffer: 'Declinar el ofrecimiento',
                removeWaitlistTicketsWarning:
                    'Al cancelar tu solicitud, te quitarás de la lista de espera para las entradas y no se te notificará en el caso de que otras entradas se queden disponibles.',
                updateWaitlist: 'Actualizar lista de espera',
                theWaitlistIsClosed: 'La lista de espera se ha cerrado.',
                youClaimed: 'Has aceptado {ticketsCount} para el siguiente evento: {eventName}.',
                yourOfferHasExpired:
                    'La oportunidad de aceptar {ticketName} ha caducado.  Puedes apuntarte de nuevo en la lista de espera, si quieres.',
                youPassedOnThisOffer:
                    'Has declinado aceptar {ticketName}.  Puedes apuntarte de nuevo en la lista de espera, si quieres.',
                offerExpired: 'Oportunidad caducada',
                offerPassed: 'Oportunidad declinada',
                noLongerOnTheWaitlist:
                    'Ya no formas parte de la lista de espera del evento: {eventName}. Si quieres, puedes apuntarte de nuevo en la lista de espera.',
            },
            filter: {
                allDays: 'Todos los días',
                filterTicketsLabel: 'Filtrar entradas:',
                ticketTypes: {
                    ALL: 'Todas las entradas',
                    INDIVIDUAL: 'Entradas individuales',
                    FAMILY: 'Entradas familiares',
                },
            },
            appleWalletAddButtonAlt: 'Botón Añadir a Cartera Apple',
        },
        terms: {
            acceptTerms: 'Aceptar términos',
            acceptEventTerms: 'Aceptar los términos del evento',
            addedToDrawing:
                '{invitor} te ha añadido para participar en la rifa de entradas del siguiente evento de Nintendo. Para poder participar, debes aceptar los términos del evento.',
            addedToEvent:
                '{invitor} te ha enviado una entrada para el siguiente evento de Nintendo. Para poder asistir a este evento, debes aceptar primero los términos del evento.',
            completeInformation: 'Rellena la siguiente información:',
            terms: '',
            viewAndAcceptTerms: 'Leer y aceptar los términos',
            pendingTermsConsent: 'Pendiente de la aceptación de términos',
        },
        error: {
            error: 'Error',
            warning: 'Atención',
            modal: {
                close: 'Cerrar',
            },
            notFound: {
                title: 'Lo sentimos, pero no se ha podido encontrar la página o el evento que buscas.',
                viewAvailableEvents: 'Ver los eventos disponibles',
            },
            eventsError:
                'Se ha producido un error al intentar mostrar los eventos. Inténtalo de nuevo más tarde.',
            eventDetailsError:
                'Se ha producido un error al intentar mostrar la información del evento. Inténtalo de nuevo más tarde.',
            activityDetailsError:
                'Se ha producido un error al intentar mostrar la información de la actividad. Inténtalo de nuevo más tarde.',
            timeSlotError:
                'Se ha producido un error al intentar mostrar las horas disponibles. Inténtalo de nuevo más tarde.',
            tempReservationError:
                'Se ha producido un error al intentar mantener esta reserva. Inténtalo de nuevo más tarde.',
            myRSVPsError:
                'Se ha producido un error al intentar acceder a la confirmación de tu participación. Inténtalo de nuevo más tarde.',
            mapError: 'No se puede mostrar el mapa de la ubicación en este momento.',
            dataCollectionError:
                'Se ha producido un error al intentar acceder a los datos. Inténtalo de nuevo más tarde.',
            reservationCouldNotBeConfirmed:
                'No se ha podido confirmar la reserva. Inténtalo de nuevo más tarde.',
            reservationCancelFailure:
                'Se ha producido un error durante la cancelación. Inténtalo de nuevo más tarde.',
            reservationFormError: 'Debes rellenar todos los campos obligatorios.',
            eventServiceUnavailable:
                'El sistema no está disponible actualmente. Inténtalo de nuevo más tarde.',
            rsvpTimeOut: 'Lo sentimos, pero tu registro ha caducado. Intenta registrarte de nuevo.',
            waitlistRsvpTimeOut:
                'Lo sentimos, pero tu oportunidad de aceptar entradas ha caducado.',
            ticketConfirmationFailed:
                'No se ha podido confirmar la entrada del evento. Repasa la información que has proporcionado e inténtalo de nuevo.',
            waitlistEntriesCreationFailed:
                'Tu solicitud de la lista de espera no ha tenido éxito. Comprueba de nuevo los detalles que has introducido e inténtalo de nuevo.',
            serviceError: {
                GP0009: 'No hay plazas disponibles en la hora solicitada.',
                GP0010: 'Ya te has registrado. No puedes registrarte para más de una actividad a la misma hora, ni para una misma actividad más de una vez al día.',
                GP0011: '',
                GP0013: 'No ha comenzado todavía el registro para esta actividad.',
                GP0014: 'El registro para esta actividad ha terminado.',
                GP0015: 'No se ha podido encontrar la reserva que se quiere cancelar.',
                GP0016: 'La hora seleccionada ya ha concluido.',
                GP0243: 'Ya se te ha añadido a una entrada para este evento.',
                GP0244: 'Ya se te ha añadido para participar en la rifa de este evento.',
                GP0252: 'Ya tienes un Warp Pipe Pass para esta actividad.',
                unknown: 'Se ha producido un error.',
            },
            serviceErrorModal: {
                GP212: {
                    title: 'Registro aún no disponible',
                    body: 'El registro para esta entrada no está disponible todavía.',
                },
                GP213: {
                    title: 'Registro concluido',
                    body: 'El registro para esta entrada ha concluido.',
                },
                GP0214: {
                    title: 'Entrada de evento no disponible',
                    body: 'Lo sentimos, pero ya no quedan plazas para la entrada de evento que has seleccionado. Consulta la disponibilidad de otras entradas.',
                },
                GP0218: {
                    title: 'Inelegible',
                    body: 'Ya participas en la rifa de esta entrada.',
                },
                GP220: {
                    title: 'Registro aún no disponible',
                    body: 'El registro para esta entrada no está disponible todavía.',
                },
                GP221: {
                    title: 'Registro concluido',
                    body: 'El registro para esta entrada ha concluido.',
                },
                unknown: {
                    title: 'Error desconocido',
                    body: 'Lo sentimos, pero se ha producido un error desconocido. Inténtalo de nuevo.',
                },
            },
            formError: {
                required: 'Campo obligatorio',
                default: 'Campo no válido',
            },
            drawingCancelingError: 'Error al cancelar la participación en una rifa',
            ticketCancelingError: 'Error al cancelar una entrada',
            timeSlotCancelingError: 'Error al cancelar un Warp Pipe Pass',
            minNumberOfGuestsError:
                'Añade {atLeastNum} miembro/s del grupo familiar de la cuenta Nintendo para proceder al registro.',
            atLeast: 'al menos {expectedNumberOfGuests}',
            waitlist: {
                maxNumberOfGuestsError:
                    'Añade un máximo de {maxGuestAmount} miembros del grupo familiar de la cuenta Nintendo para registrarte.',
                noTicketsSelectedError: 'Selecciona al menos una entrada de evento para continuar',
                claimTempTicketsError:
                    'Se ha producido un error al intentar aceptar la o las entradas de la lista de espera.',
                passWaitlistError:
                    'Se ha producido un error al intentar declinar la o las entradas de la lista de espera.',
            },
        },
        drawing: {
            drawing: 'Rifa',
            firstComeFirstServed: 'Por orden de llegada',
            enterForAChance:
                'Participa para tener la oportunidad de asistir a un evento o actividad',
            youHaveAlreadyEntered: 'Ya participas en la rifa de esta actividad.',
            drawingRules: 'Reglas de la rifa',
            ruleX: 'Regla: {rule}',
            status: {
                pending: 'Pendiente de la rifa: {partySize} {type}',
                notSelected: '',
                notWinner: 'No has resultado elegido/a',
                winner: 'Has resultado elegido/a',
            },
        },
        account: {
            accountRequired: 'Se requiere una cuenta Nintendo',
            pleaseLogin: {
                basic: '{0} en tu cuenta Nintendo.',
                toView: 'Debes {0} en tu cuenta Nintendo para ver tus {1}.',
                toAccept: 'Debes {0} en tu cuenta Nintendo para aceptar los términos.',
            },
            requiredToRSVP:
                'Se requiere una cuenta Nintendo para registrarse. Inicia sesión o crea una cuenta gratuita para continuar.',
            rsvpWillBeHeld: '',
            time: 'Minutos restantes: {10}',
            checkIn: {
                title: '',
                presentCode: '',
                poorInternetConnectivity:
                    'En caso de que la conexión a internet no sea estable en el evento, te recomendamos que imprimas el Warp Pipe Pass desde esta página o desde el correo electrónico que hemos enviado a la dirección de correo electrónico vinculada a tu cuenta Nintendo. (Si no puedes ver dicho correo electrónico, comprueba tu carpeta de spam.)',
                checkInCode: '',
                appleWalletTM:
                    'Apple Wallet is a trademark of Apple Inc., registered in the U.S. and other countries and regions.',
            },
            passes: {
                warpPipePass: 'Warp Pipe Pass',
                warpPipePasses: 'Warp Pipe Passes',
            },
            rsvps: {
                activeRSVPs: '',
                drawingEntries: '',
                rsvps: 'Entradas y Warp Pipe Passes',
                viewPass: 'Ver Warp Pipe Pass',
                reservationUpdated: 'Se ha actualizado: {0}.',
                reservationRemoved: 'Se ha eliminado: {0}.',
                ticketAdded: 'Has recibido con éxito tu {ticketName}.',
                drawingTicketAdded: 'Te has unido con éxito a la rifa de {ticketName}.',
                warpPipePassAdded: 'Has recibido con éxito tu Warp Pipe Pass para {activityName}.',
                waitlistAdded:
                    'Se te ha apuntado en la lista de espera del siguiente evento: {eventName}.',
                waitlistsUpdated:
                    'Se han actualizado tus preferencias en la lista de espera del siguiente evento: {eventName}.',
                edit: {
                    title: 'Cambiar la hora',
                },
                cancel: {
                    title: '',
                    entry: 'Cancelar participación',
                    pass: '',
                    ticket: 'Cancelar entrada',
                    areYouSure: '',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/es/apple_wallet_badge_es.svg'),
                    },
                    noneFound: '',
                    requiredForCheckIn: 'Se requiere este Warp Pipe Pass para registrarse.',
                    addToCalendar: 'Añadir al calendario',
                },
            },
        },
        noneFound: {
            events: 'No hay eventos para los que registrarse actualmente. Vuelve por aquí más tarde.',
            activities: 'No hay actividades disponibles para este evento actualmente.',
            timeSlots: 'No se han encontrado horas disponibles para esta actividad.',
            reservations: '',
            warpPipePasses: 'No tienes ningún Warp Pipe Pass activo en este momento.',
            tickets: 'No tienes ninguna entrada de evento activa en este momento.',
        },
        footer: {
            disclaimer:
                'El Warp Pipe Pass y/o entrada solo puede usarse una vez, no es transferible y no está permitida su venta. El Warp Pipe Pass y/o entrada puede canjearse para recibir acceso prioritario en la cola para acceder a un juego o evento determinados a una hora designada; por el contrario, no se podrá canjear si se utiliza a una hora que no sea la designada. QR Code (código QR) es una marca registrada de DENSO WAVE INCORPORATED. Las marcas de Nintendo son propiedad de Nintendo. © Nintendo.',
            privacyPolicy: 'Política de privacidad',
            privacyPolicyUrl:
                'https://www.nintendo.com/es-es/Informacion-legal/Politica-de-privacidad-de-este-sitio-web/Politica-de-privacidad-de-este-sitio-web-637785.html',
            termsOfUse: '',
            termsOfUseUrl: '',
        },
        dates: {
            dayOfWeek: 'dddd,',
            fullDate: 'ddd, DD MMMM, YYYY - H:mm',
            dateRangeFirst: 'DD MMM',
            dateRangeSecond: 'DD MMM, YYYY',
            hourMinute: 'H:mm',
            hourMinutesTimezone: 'H:mm',
            monthDateYear: 'DD MMM, YYYY',
            monthDateYearLong: 'DD MMMM, YYYY',
            reservationDate: 'dddd, D MMM, YYYY',
            reservationDateShort: 'ddd, D MMM, YYYY',
            reservationTime: 'H:mm',
            registrationDate: 'ddd, DD MMM, YYYY HH:mm',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'H:mm',
                timeSlotHourMinute: 'H:mm',
            },
            eventTickets: {
                dateRange: 'ddd, DD MMMM, YYYY',
                time: 'H:mm',
                same_day_time: 'HH:mm',
                timeZone: '',
                cancel: {
                    time: 'dddd, D MMM, YYYY - HH:mm',
                },
                waitlistClaimBy: 'H:mm [del] dddd, D MMMM',
            },
            birthday: 'D MMMM, YYYY',
            formatting: {
                lowerCaseDayOfWeekMidSentence: true,
                ISO8601ToTimezone: '',
            },
        },
        countries: {
            title: 'País: {0}',
            values: {
                ALL: 'Todos',
                US: 'EE. UU.',
                CA: 'Canadá',
                AU: 'Australia',
                GB: 'Reino Unido e Irlanda',
                DE: 'Alemania',
                FR: 'Francia',
                ES: 'España',
                IT: 'Italia',
                NL: 'Países Bajos',
                MX: 'México',
            },
        },
        locales: {
            en_US: 'Inglés (EE. UU.)',
            en_GB: 'Inglés (Reino Unido)',
            en_AU: 'Inglés (Australia)',
            de_DE: 'Alemán',
            fr_FR: 'Francés (Francia)',
            fr_CA: 'Francés (Canadá)',
            it_IT: 'Italiano',
            es_ES: 'Español (España)',
            es_MX: 'Español (México)',
            nl_NL: 'Neerlandés',
            // pt_PT: '',
        },
        files: {
            fileCategory: {
                GENERAL_INFO: 'Información general',
                LEGAL: 'Información legal',
                TOURNAMENT_INFO: 'Información del torneo',
            },
        },
    },
    en_US,
);

export default es_ES;
