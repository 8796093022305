import moment from 'moment';

import en_US, { PublicLocalizationObject } from './en_US';
import en_GB from './en_GB';
import en_AU from './en_AU';
import de_DE from './de_DE';
import es_ES from './es_ES';
import es_MX from './es_MX';
import fr_CA from './fr_CA';
import fr_FR from './fr_FR';
import it_IT from './it_IT';
import nl_NL from './nl_NL';

// We are bootstrapping the locales from moment.js here.
import 'moment/locale/en-gb';
import 'moment/locale/en-au';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/nl';

// Override Locale data in moment.js to fit our demands.

// FR (There is no explicit fr-FR in moment.js)
moment.updateLocale('fr', {
    weekdaysShort: 'dim_lun_mar_mer_jeu_ven_sam'.split('_'),
});

// NL (There is no explicit nl-NL in moment.js)
// And for some reason, 'ddd' is just 'dd' with a dot. Who did this? Why do they hate us?
moment.updateLocale('nl', {
    weekdaysShort: 'zo_ma_di_wo_do_vr_za'.split('_'),
});

export const AVAILABLE_LOCALES: Record<string, PublicLocalizationObject> = {
    'en-US': en_US,
    'en-GB': en_GB,
    'en-AU': en_AU,
    'de-DE': de_DE,
    'es-ES': es_ES,
    'es-MX': es_MX,
    'fr-CA': fr_CA,
    'fr-FR': fr_FR,
    'it-IT': it_IT,
    'nl-NL': nl_NL,
};

export default AVAILABLE_LOCALES;
