import React from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    formatLocalizedAddress,
    formatDateRange,
    convertISO8601ToTimezone,
} from '../../../utils/FormattingUtils';
import LocalizationContext from '../../../context/LocalizationContext';
import { Location } from '../../../utils/types';
import { FormattedTimeRange } from '../../common/FormattedTimeRange';
import TicketAltIcon from '../../common/icons/TicketAltIcon';

type Props = {
    /** Title of the details section. */
    title: string;
    /** Start time of the resource */
    startTime: string;
    /** End time of the resource */
    endTime: string;
    /** Location where the resource is taking place at */
    location: Location;
    /** Is drawing or not */
    drawing?: boolean;
    /** Optional drawing rules */
    drawingRules?: string;
    /** If there is a type associated with this resource, uses this FontAwesome icon class */
    typeIcon?: string;
    /** Name of the resource type */
    typeName?: string | Array<string> | JSX.Element;
    /** Boolean field to show the hours for the resource start/end time */
    showHours?: boolean;
    /** Boolean field to show the duration of a given model */
    showDuration?: boolean;
    /** Whether to show the ticket type icon and text */
    showTicketType?: boolean;
};

/**
 * Component for displaying registration details for activities, time slots, tickets, etc.
 */
const DetailsBlock = ({
    title,
    startTime,
    endTime,
    location,
    drawing,
    drawingRules,
    typeIcon,
    typeName,
    showHours,
    showDuration,
    showTicketType = true,
}: Props) => {
    const localizedStrings = React.useContext(LocalizationContext);

    // Format dates
    const timezone = convertISO8601ToTimezone(
        startTime,
        location.time_zone,
        localizedStrings.dates.formatting.ISO8601ToTimezone,
    );
    const dateRange = formatDateRange(
        startTime,
        endTime,
        location.time_zone,
        localizedStrings,
        localizedStrings.dates.eventTickets.dateRange,
    );

    return (
        <>
            <Row>
                <Col md={12}>
                    <h4 className="mt-4 section-header">
                        <TicketAltIcon className="me-2 nav-link-icon" />
                        {title}
                    </h4>
                    <Container>
                        <table className="mt-1 tbl_event_details">
                            <tbody>
                                {/* Detail type / information */}
                                {typeIcon && typeName ? (
                                    <tr>
                                        <td
                                            aria-label={
                                                localizedStrings.activities.details.iconLabel
                                                    .activityType
                                            }
                                            valign="top"
                                        >
                                            <i className={typeIcon || 'fas fa-question'} />
                                        </td>
                                        <td>{typeName}</td>
                                    </tr>
                                ) : null}
                                {/* Time (start/end + additional hours info if enabled) */}
                                <tr>
                                    <td
                                        aria-label={
                                            localizedStrings.activities.details.iconLabel
                                                .activityTime
                                        }
                                    >
                                        <i className="far fa-calendar-alt" />
                                    </td>
                                    <td>
                                        <p className="mb-0">{dateRange}</p>
                                    </td>
                                </tr>
                                {showHours ? (
                                    <tr>
                                        <td
                                            aria-label={
                                                localizedStrings.activities.details.iconLabel
                                                    .activityTime
                                            }
                                        >
                                            <i className="far fa-clock" />
                                        </td>
                                        <td>
                                            <p className="mb-0">
                                                <span>
                                                    <FormattedTimeRange
                                                        startDate={startTime}
                                                        endDate={endTime}
                                                        timezone={location.time_zone}
                                                    />{' '}
                                                    {timezone}
                                                </span>{' '}
                                                <span>
                                                    {showDuration
                                                        ? `(${moment
                                                              .duration(
                                                                  moment(endTime).diff(startTime),
                                                              )
                                                              .asMinutes()}${
                                                              localizedStrings.reservations.minutes
                                                          })`
                                                        : null}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                ) : null}
                                {/* Location info */}
                                <tr>
                                    <td
                                        aria-label={
                                            localizedStrings.activities.details.iconLabel.location
                                        }
                                        valign="top"
                                    >
                                        <i className="far fa-map-marker-alt" />
                                    </td>
                                    <td>
                                        <p className="mb-0">{location.name}</p>
                                        <p className="text-muted mt-0 mb-0">
                                            <small data-testid="full-address">
                                                {formatLocalizedAddress(location, localizedStrings)}
                                            </small>
                                        </p>
                                    </td>
                                </tr>
                                {/* Drawing vs First Come First Served */}
                                {showTicketType ? (
                                    <tr>
                                        <td
                                            aria-label={
                                                localizedStrings.activities.details.iconLabel
                                                    .registration
                                            }
                                            valign="top"
                                        >
                                            <TicketAltIcon className="me-1" />
                                        </td>
                                        <td>
                                            <p className="mb-0">
                                                {drawing
                                                    ? localizedStrings.drawing.drawing
                                                    : localizedStrings.drawing.firstComeFirstServed}
                                            </p>
                                        </td>
                                    </tr>
                                ) : null}
                                {drawing ? (
                                    <tr>
                                        <td
                                            aria-label={`icon-spacer-${localizedStrings.drawing.drawingRules}`}
                                        />
                                        <td
                                            aria-label={localizedStrings.drawing.drawingRules}
                                            data-testid="drawing-rules"
                                        >
                                            <p className="mt-0 mb-0">{drawingRules}</p>
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

export default DetailsBlock;
