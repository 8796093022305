import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';
import uniqueId from 'lodash.uniqueid';
import { LocalizedStrings } from 'react-localization';
import sanitizeHtml from 'sanitize-html';
import LocalizedDate, { FormatSpecialCase } from '../components/common/LocalizedDate';
import { PublicLocalizationObject } from '../../resources/locales/en_US';
import { getUserLocale } from '../context/LanguageContext';
import { Location } from './types';
import { checkIfSameDay } from './DateUtils';

/**
 * Function for formatting a pair of dates into a date range
 * @param startDate {string} - Starting date
 * @param endDate {string} - Ending date
 * @param timezone {string} - target timezone
 * @param localizedText {Object} - Localization object
 * @param customFormat - Custom date format
 * @return returns a string in the format of "MMM DD - MMM DD YYYY". If it reside on the same date, it will return "MMM DD YYYY"
 */
export const formatDateRange = (
    startDate: string,
    endDate: string,
    timezone: string,
    localizedText: PublicLocalizationObject,
    customFormat = '',
) => {
    // If the dates are the same, return a single date rather than a date range.
    if (
        endDate == null ||
        convertISO8601ToTimezone(startDate, timezone, localizedText.dates.dateRangeFirst) ===
            convertISO8601ToTimezone(endDate, timezone, localizedText.dates.dateRangeFirst)
    ) {
        return (
            <LocalizedDate
                date={startDate}
                format={customFormat || localizedText.dates.dateRangeSecond}
                timezone={timezone}
            />
        );
    }

    return (
        <>
            <LocalizedDate
                date={startDate}
                format={customFormat || localizedText.dates.dateRangeFirst}
                timezone={timezone}
            />
            {localizedText.dates.range}
            <LocalizedDate
                date={endDate}
                format={customFormat || localizedText.dates.dateRangeSecond}
                timezone={timezone}
                specialCase={FormatSpecialCase.MIDSENTENCE}
            />
        </>
    );
};

/**
 * Render start and end date and time based on if the dates are the same day or not.
 * @param startDate - start date
 * @param endDate - end date
 * @param timeZone - location timezone
 * @param dateFormat - format for the dates
 * @param timeFormat - time format
 * @param timeZoneFormat - time zone format
 */
export const formatMultipleDateRanges = (
    startDate: string,
    endDate: string | null,
    timeZone: string,
    dateFormat: string,
    timeFormat: string,
    timeZoneFormat: string,
) => {
    let isSameDay = false;
    if (endDate) isSameDay = checkIfSameDay(startDate, endDate, timeZone);

    return (
        <>
            <LocalizedDate date={startDate} format={dateFormat} timezone={timeZone} />
            {endDate ? (
                <>
                    {isSameDay ? (
                        <>
                            {' '}
                            <LocalizedDate
                                date={startDate}
                                format={timeFormat}
                                timezone={timeZone}
                            />
                            {' - '}
                            <LocalizedDate
                                date={endDate}
                                format={timeFormat}
                                timezone={timeZone}
                            />{' '}
                            <LocalizedDate
                                date={endDate}
                                format={timeZoneFormat}
                                timezone={timeZone}
                            />
                        </>
                    ) : (
                        <>
                            {' - '}
                            <LocalizedDate date={endDate} format={dateFormat} timezone={timeZone} />
                        </>
                    )}
                </>
            ) : null}
        </>
    );
};

/**
 * format single date time
 * @param date - date you want formatted
 * @param format - format you want it to be
 * @param timezone - timezone
 * @param locale - the locale to format for
 */
export const formatDateTimeForLocale = (
    date: string,
    format: string,
    timezone: string,
    locale: string,
): string => {
    return moment(date).locale(locale).tz(timezone).format(format);
};

/**
 * format single date time w/o a timezone
 * @param date - date you want formatted
 * @param format - format you want it to be
 * @param locale - the locale to format for
 */
export const formatDateTimeNoTimezoneForLocale = (
    date: string,
    format: string,
    locale: string,
): string => {
    const dateTime = moment(date).locale(locale).format(format);
    return dateTime[0].toUpperCase() + dateTime.slice(1);
};

/**
 * format single date time w/o a timezone
 * @param date - date you want formatted
 * @param format - format you want it to be
 */
export const formatDateTimeNoTimezone = (date: string, format: string): string => {
    const locale = getUserLocale();
    return formatDateTimeNoTimezoneForLocale(date, format, locale);
};

/**
 * Function for formatting a city and region string into a single formatted string.
 * @param city {string} - String of the city
 * @param region {string} - String of the region
 * @return returns a formatted string in the format of "CITY, REGION"
 */
export const formatCityRegion = (city: string, region: string) => `${city}, ${region}`;

export const formatAddressLines = (location: Location) => {
    const addressLines = [location.address_line_1, location.address_line_2];
    const validAddressLines: string[] = [];

    for (let i = 0; i < addressLines.length; i++) {
        const addressLine = addressLines[i];
        if (addressLine) {
            validAddressLines.push(addressLine);
        }
    }
    return `${validAddressLines.join(' ')}`;
};

/**
 * Function for formatting the customer-oriented address in accordance with localized standards.
 * @param location - API location object
 * @param localizedStrings - LocalizedStrings object
 */
export const formatLocalizedAddress = (
    location: Location,
    localizedStrings: LocalizedStrings<any>,
): string | string[] => {
    return location.hide_region
        ? localizedStrings.formatString(localizedStrings.general.addressFormatHideRegion, {
              address: formatAddressLines(location),
              city: location.city,
              postal_code: location.postal_code,
          })
        : localizedStrings.formatString(localizedStrings.general.addressFormat, {
              address: formatAddressLines(location),
              city: location.city,
              region: location.region,
              postal_code: location.postal_code,
          });
};

/**
 * Each Tooltip component should have a unique id, otherwise you could run into an issue where
 * 2 or more buttons might get the same id causing multiple tooltips to show at the same time.
 * Here we are using lodash.uniqueId to generate an id that should be unique for each tooltip we
 * create. (e.g. output 'tooltip-123')
 *
 * Usage:
 * const tooltipId = generateTooltipId();
 * <ReactTooltip id={tooltipId} ... />
 * <span ...
 *      data-for={tooltipId}
 *      data-placement="top"
 *      data-tip={tooltip}
 *      data-testid={`tooltip-${activity.activity_id}`}
 * > {component} </span>
 */
export const generateTooltipId = (): string => {
    return uniqueId('tooltip-');
};

const DEFAULT_FORMAT = 'MM/DD/YYYY hh:mm A z';

/**
 * Utility function to localize from ISO8601 format to timezone.
 * Returns an empty string if no format is provided.
 *
 * @param {string} date - ISO8601-formatted date
 * @param {string} timeZone - requested timezone
 * @param {string} format - Format of the output string
 * @return {string} - Formatted date
 */
export const convertISO8601ToTimezone = (
    date: string,
    timeZone: string,
    format: string = DEFAULT_FORMAT,
) => (format ? moment(date).tz(timeZone).format(format) : '');

/**
 * Utility function to localize from ISO8601 format to the local timezone. This method uses moment.js
 * to make a best guess at the user's timezone.
 *
 * @param {string} date - ISO8601-formatted date
 * @param {string} format - Format of the output string
 * @param {boolean} ignoreCachedTimeZone - moment.tz.guess caches the previously guessed time zone by default, so this
 *                  parameter, if true, forces a fresh time zone guess and updates the cached value with the new guess
 * @return {string} - Formatted date. If the date is null, it will return empty.
 */
export const convertISO8601ToLocal = (
    date: string,
    format: string = DEFAULT_FORMAT,
    ignoreCachedTimeZone: boolean = false,
) => (date ? moment(date).tz(moment.tz.guess(ignoreCachedTimeZone)).format(format) : '');

/**
 * Utility function to turn a formatted date into a Date object
 *
 * @param {string} date - The date string to be parsed
 * @param {string} format - Date format of the input string
 * @return {Date} - Date object is the dateString was valid, null if it was not
 */
export const convertStringToDate = (date: string, format: string = DEFAULT_FORMAT) => {
    const dateObj = moment(date, format).toDate();
    return dateObj.valueOf() ? dateObj : null;
};

/**
 * Sanitize the HTML tags in data and make sure its safe for displaying
 * @param {string} data string data to sanitize
 */
export const sanitizeForDisplay = (data: string) => {
    return sanitizeHtml(data, {
        allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            '*': ['style'],
        },
    });
};
